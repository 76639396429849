<div class="row animated fadeIn fast">
    <div class="col-12">
        <div class="card">
            <div class="card-body">

                <input type="text" class="form-control" placeholder="Buscar médico..." #txtTermino (keyup)="buscar( txtTermino.value )" />

            </div>
        </div>
    </div>
</div>

<div class="row animated fadeIn fast" *ngIf="cargando">
    <div class="col-12">

        <div class="alert alert-info text-center">
            <h4 class="alert-heading">Cargando</h4>
            <i class="fa fa-spin fa-refresh fa-2x"></i>
            <p class="mb-0">Por favor espere</p>
        </div>

    </div>
</div>


<div class="row" *ngIf="!cargando">
    <div class="col-12">
        <div class="card">
            <div class="card-body">


                <div class="row">
                    <div class="col-8">
                        <h4 class="card-title">Medicos ({{ medicos.length }})</h4>
                        <h6 class="card-subtitle">
                            Medicos registrados en Medscan
                        </h6>
                    </div>

                    <div class="col">
                        <div class="text-right">
                            <a class="btn btn-primary" routerLink="/dashboard/medico/nuevo">
                                <i class="fa fa-hospital-o"></i> Crear médico
                            </a>
                        </div>
                    </div>

                </div>



                <div class="table-responsive">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th class="w100">Foto</th>
                                <th>Nombre</th>
                                <th>Apellidos</th>
                                <th>Hospital</th>
                                <th class="w100 text-nowrap">Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let medico of medicos  | paginate: {
                                itemsPerPage: 4, 
                                currentPage: p}">
                                <td class="text-center">
                                    <img [src]="medico.img | imagen:'medicos'" [alt]="medico.nombre" class="w100 cursor" (click)="abrirModal(medico)">
                                </td>
                                <td>
                                    {{ medico.nombre }}
                                </td>
                                <td>
                                    {{ medico.apellido1 }} {{ medico.apellido2 }}
                                </td>
                                <td>
                                    {{ medico.hospital.nombre }}
                                </td>

                                <td class="text-nowrap text-center">
                                    <a [routerLink]="['/dashboard','medico', medico.mid ]" data-toggle="tooltip" data-original-title="Editar">
                                        <i class="fa fa-pencil text-inverse m-r-10"></i>
                                    </a>

                                    <a class="cursor" data-toggle="tooltip" data-original-title="Borrar" (click)="borrarMedico( medico )">
                                        <i class="fa fa-close text-danger"></i>
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div>
                        <pagination-controls (pageChange)="p = $event" previousLabel="Anterior" nextLabel="Siguiente"></pagination-controls>
                    </div>
                </div>
                <!--
              <button class="btn btn-secondary">Anteriores</button>
              &nbsp;
              <button class="btn btn-secondary">Siguientes</button>
             -->
                <!--  <div *ngIf="txtTermino.value.length === 0">
                <button (click)="cambiarPagina(-5)" class="btn btn-secondary">Anteriores</button> &nbsp;
                <button (click)="cambiarPagina(5)" class="btn btn-secondary">Siguientes</button>
            </div>
        -->
            </div>
        </div>
    </div>
</div>