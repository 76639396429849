<!-- ============================================================== -->
<!-- Topbar header - style you can find in pages.scss -->
<!-- ============================================================== -->
<header class="topbar">
    <nav class="navbar top-navbar navbar-expand-md navbar-light">
        <!-- ============================================================== -->
        <!-- Logo -->
        <!-- ============================================================== -->
        <div class="navbar-header">
            <a class="navbar-brand" href="index.html">
                <!-- Logo icon --><b>
                    <!--You can put here icon as well // <i class="wi wi-sunset"></i> //-->
                    <!-- Dark Logo icon -->
                    <img src="./assets/images/medscan-logo-icon.png" alt="homepage" class="dark-logo margin-right" />
                    <!-- Light Logo icon -->
                    <img src="./assets/images/medscan-logo-light-icon.png" alt="homepage" class="light-logo margin-right" />
                </b>
                <!--End Logo icon -->
                <!-- Logo text --><span>
                    <!-- dark Logo text -->
                    <img src="./assets/images/medscan-text.png" alt="homepage" class="dark-logo" />
                    <!-- Light Logo text -->    
                    <img src="./assets/images/medscan-light-text.png" class="light-logo" alt="homepage" /></span> </a>
        </div>
        <!-- ============================================================== -->
        <!-- End Logo -->
        <!-- ============================================================== -->
        <div class="navbar-collapse">
            <!-- ============================================================== -->
            <!-- toggle and nav items -->
            <!-- ============================================================== -->
            <ul class="navbar-nav mr-auto">
                <!-- This is  -->
                <li class="nav-item"> <a class="nav-link nav-toggler hidden-md-up waves-effect waves-dark" href="javascript:void(0)"><i class="ti-menu"></i></a> </li>
                <li class="nav-item"> <a class="nav-link sidebartoggler hidden-sm-down waves-effect waves-dark" href="javascript:void(0)"><i class="ti-menu"></i></a> </li>
                <li class="nav-item hidden-sm-down"></li>
            </ul>
            <!-- ============================================================== -->
            <!-- User profile and search -->
            <!-- ============================================================== -->
            <ul class="navbar-nav my-lg-0">

                <li class="nav-item hidden-xs-down search-box"> <a class="nav-link hidden-sm-down waves-effect waves-dark" href="javascript:void(0)"><i class="ti-search"></i></a>
                    <form class="app-search"
                            (submit)="buscar( txtTermino.value )">
                        <input type="text" 
                                class="form-control" 
                                placeholder="Buscar.."
                                #txtTermino> 
                        <a class="srh-btn"><i class="ti-close"></i></a> 
                    </form>
                </li>
                <!-- ============================================================== -->
                <!-- Profile -->
                <!-- ============================================================== -->
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle waves-effect waves-dark" href="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <img [src]="usuario.imagenUrl" alt="user" class="profile-pic" />
                    </a>
                    <div class="dropdown-menu dropdown-menu-right animated fadeIn fast">
                        <ul class="dropdown-user">
                            <li>
                                <div class="dw-user-box">
                                    <div class="u-img"><img [src]="usuario.imagenUrl" alt="user"></div>
                                    <div class="u-text">
                                        <h4>{{ usuario.nombre }}</h4>
                                        <p class="text-muted">
                                            {{ usuario.email | slice:0:20 }}{{ (usuario.email.length > 20) ? '...': '' }}
                                        </p>
                                        <a routerLink="./perfil" class="btn btn-rounded btn-danger btn-sm">Ver Perfil</a>
                                    </div>
                                </div>
                            </li>
                            <li role="separator" class="divider"></li>
                            <li><a routerLink="./perfil"><i class="ti-user"></i> Mi Perfil</a></li>
                            <!--<li><a href="#"><i class="ti-wallet"></i> My Balance</a></li>
                            <li><a href="#"><i class="ti-email"></i> Inbox</a></li>-->
                            <li role="separator" class="divider"></li>
                            <li><a routerLink="account-settings"><i class="ti-settings"></i> Configuración de cuenta</a></li>
                            <li role="separator" class="divider"></li>
                            <li>
                                <a (click)="logout()" class="cursor">
                                    <i class="fa fa-power-off"></i> Cerrar Sesión
                                </a>
                            </li>
                        </ul>
                    </div>
                </li>
            </ul>
        </div>
    </nav>
</header>
<!-- ============================================================== -->
<!-- End Topbar header -->
<!-- ============================================================== -->