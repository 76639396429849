import { environment } from '../../environments/environment';

const base_url = environment.base_url;


export class Usuario {

    constructor(
        public nombre?: string,
        public email?: string,
        public password?: string,
        public img?: string,
        public google?: boolean,
        public role?: 'ADMIN_ROLE' | 'USER_ROLE'  | 'MEDIC_ROLE' | 'TMS_ROLE' | 'SECRET_ROLE' ,
        public uid?: string,
        // No pertenece a esta clase
        public _id?: string,
        public tipoExamen?: string,
        public fechaIngreso?: string,
        public estado?: string,
        public formaPago?: string,
        public horaIngreso?: string,
    ) {}

    public get imagenUrl() {

        if (!this.img) {
            return `${ base_url }/upload/usuarios/no-image`;
        } else if (this.img.includes('https')) {
            return this.img;
        } else if ( this.img ) {
            return `${ base_url }/upload/usuarios/${ this.img }`;
        } else {
            return `${ base_url }/upload/usuarios/no-image`;
        }
    }
}
