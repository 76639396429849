import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment.prod';

import { map } from 'rxjs/operators';

import { Mail } from '../models/mail.model'
import { Examen } from '../models/examenes.model';


const base_url = environment.base_url;

@Injectable({
  providedIn: 'root'
})
export class MailService {

  constructor(
    private http: HttpClient,
  ) { }

  public enviarCorreo( mail:  Examen  ) {
    const url = `${ base_url }/send-mail`;
    return this.http.post( url, mail );
  }
}
