import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';

import { Paciente } from 'src/app/models/paciente.model';

import { FormularioPacientesService } from '../../../services/formulario-pacientes.service';
import { delay } from 'rxjs/operators';
import * as dayjs from 'dayjs';
import { ClassGetter } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-formulario-pacientes',
  templateUrl: './formulario-pacientes.component.html',
  styles: [
  ]
})
export class FormularioPacientesComponent implements OnInit {
  public pacienteSeleccionado: Paciente;

  public pacienteForm = this.fb.group({
    nombre: ['', [Validators.required, Validators.minLength(3)]],
    apellido1: ['', [Validators.required, Validators.minLength(3)]],
    apellido2: ['', [Validators.required, Validators.minLength(3)]],
    rut: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(12)]],
    correo: ['', [Validators.email]],
    fechaNac: [null, [Validators.required]],
    telefono: ['', [Validators.required, Validators.maxLength(12)]],
  });

  constructor(private fb: FormBuilder,
    private pacienteService: FormularioPacientesService,
    private router: Router,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(({ id }) => this.cargarPaciente(id));
  }

  public calcularEdad( fechaNac ) {
    fechaNac = Date.parse(fechaNac);
    let fechaHoy =  new Date();
    let timeDiff = Math.abs(+fechaHoy - fechaNac);
    fechaNac = (Math.ceil((timeDiff / (1000 * 3600 * 24)) / 365)-1);
    return fechaNac;
  }

  public cargarPaciente(id: string) {

    if (id === 'nuevo') {
      return;
    }

    this.pacienteService.obtenerPacientePorId(id)
      .pipe(
        delay(100)
      )
      .subscribe(paciente => {

        if (!paciente) {
          return this.router.navigateByUrl(`/dashboard/lista-pacientes`);
        }
        let { nombre, apellido1, apellido2, rut, telefono, correo, fechaNac } = paciente;
        this.pacienteSeleccionado = paciente;
        let nuevaFecha = dayjs(fechaNac).format('YYYY-MM-DD');
        fechaNac = nuevaFecha
        this.pacienteForm.setValue({ nombre, apellido1, apellido2, rut, fechaNac, telefono, correo });
        console
      });
  }

  public guardarPaciente() {
    const { nombre } = this.pacienteForm.value;
    const { apellido1 } = this.pacienteForm.value;
    if (this.pacienteSeleccionado) {
      // Actualizar
      const data = {
        ...this.pacienteForm.value,
        _id: this.pacienteSeleccionado._id,
      }
      this.pacienteService.actualizarPaciente(data)
        .subscribe(resp => {
          Swal.fire('Actualizado', `${nombre} actualizado correctamente`, 'success');
        }, (err) => {
          console.log(err);
          Swal.fire('Error', err.error.errors.nombre.msg, 'error');
        }
        )
      // crear
    } else {
      this.pacienteService.crearPaciente(this.pacienteForm.value)
        .subscribe((resp: any) => {
          Swal.fire('Creado', `${nombre} ${apellido1} creado correctamente`, 'success');
          this.router.navigateByUrl(`/dashboard/lista-pacientes`)
        });
    }
  }

  public validarRut() {
    const { rut } = this.pacienteForm.value;
    const rutSinDigito = rut.split('-')[0];
    const digitoVerificador = rut.split('-')[1];
    const rutSinDigitoAlrevesArray = rutSinDigito.split('').reverse();
    const serieNumeros1 = ['2', '3', '4', '5', '6', '7', '2'];
    const serieNumeros2 = ['2', '3', '4', '5', '6', '7', '2', '3'];
    const serieNumeros3 = ['2', '3', '4', '5', '6', '7', '2', '3', '4'];
    let cont = 0;
    let arraySuma = [];
    let laSuma = 0;

    for (let num of rutSinDigitoAlrevesArray) {
      if (rutSinDigito.length === 7) {
        let multiplicacion = +num * +serieNumeros1[cont];
        arraySuma.push(multiplicacion);
      } else if (rutSinDigito.length === 8) {
        let multiplicacion = +num * +serieNumeros2[cont];
        arraySuma.push(multiplicacion);
      } else if (rutSinDigito.length === 9) {
        let multiplicacion = +num * +serieNumeros3[cont];
        arraySuma.push(multiplicacion);
      }
      cont++;
    }
    arraySuma.forEach((numero) => laSuma += numero);
    let divisionTruncada = Math.trunc(laSuma / 11);
    let multiplicar11 = divisionTruncada * 11;
    let restaLasSumaMult = Math.abs(laSuma - multiplicar11);
    let digitoFinal = Math.abs(restaLasSumaMult - 11);

    if (digitoFinal === 10) {
      let digito = String(digitoFinal);
      digito = 'k';
      if (digito === digitoVerificador.toLowerCase()) {
        this.guardarPaciente();
      } else {
        Swal.fire('Rut inválido', 'Ingrese un rut válido', 'info');
      }
    } else if (digitoFinal === 11) {
      digitoFinal = 0
      if (digitoFinal === +digitoVerificador) {
        this.guardarPaciente();
      } else {
        Swal.fire('Rut inválido', 'Ingrese un rut válido', 'info');
      }
    } else {
      if (digitoFinal === +digitoVerificador) {
        this.guardarPaciente();
      } else {
        Swal.fire('Rut inválido', 'Ingrese un rut válido', 'info');
      }
    }
  }
}
