import { NgModule } from '@angular/core';
import { ImagenPipe } from './imagen.pipe';
import { DatePipe } from '@angular/common'



@NgModule({
  declarations: [ ImagenPipe],
  exports: [ ImagenPipe ],
  providers: [DatePipe]
})
export class PipesModule { }
