import { Component, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { UsuarioService } from '../../services/usuario.service';
import Swal from 'sweetalert2';

declare const gapi: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  public formSubmitted = false;
  public auth2: any;
  public siteKey: string;

  public loginForm = this.fb.group({
    email: [localStorage.getItem('email') || '', [Validators.required, Validators.email]],
    password: ['', Validators.required],
    recaptcha: [false, Validators.required],
    remember: [false]
  });


  constructor(private router: Router,
    private fb: FormBuilder,
    private usuarioService: UsuarioService,
    private ngZone: NgZone) {

    this.siteKey = '6LczAFQaAAAAAEJQ9mQxPxLqITsFiVdQyX4AoPg_';
  }

  ngOnInit(): void {
    this.renderButton();
  }


  public login() {
    this.formSubmitted = true;
    if (this.loginForm.get('recaptcha').value) {
      this.usuarioService.login(this.loginForm.value)
        .subscribe(resp => {
          if (this.loginForm.get('remember').value) {
            localStorage.setItem('email', this.loginForm.get('email').value);
            localStorage.setItem('emailUser', this.loginForm.get('email').value);
            console.log('login: ', resp);
          } else {
            localStorage.removeItem('email');
          }
          // Navegar al Dashboard
          this.router.navigateByUrl('/');

        }, (err) => {
          // Si sucede un error
          Swal.fire('Error', err.error.msg, 'error');
        });
    }
  }

  public renderButton() {
    gapi.signin2.render('my-signin2', {
      'scope': 'profile email',
      'width': 240,
      'height': 50,
      'longtitle': true,
      'theme': 'dark',
    });

    this.startApp();
  }

  public async startApp() {
    await this.usuarioService.googleInit();
    this.auth2 = this.usuarioService.auth2;
    this.attachSignin(document.getElementById('my-signin2'));
  };

  public attachSignin(element) {
    this.auth2.attachClickHandler(element, {},
      (googleUser) => {
        const id_token = googleUser.getAuthResponse().id_token;
        this.usuarioService.loginGoogle(id_token)
          .subscribe(resp => {
            // Navegar al Dashboard
            localStorage.setItem('emailUser', resp.email);
            this.ngZone.run(() => {
              this.router.navigateByUrl('/');
            })
          });
      }, (error) => {
        alert(JSON.stringify(error, undefined, 2));
      });
  }

  public aceptaCaptcha() {
    if (!this.loginForm.get('recaptcha').value && this.formSubmitted) {
      return true;
    }
  }

}
