
import { Component, OnInit } from '@angular/core';
import { delay } from 'rxjs/operators';
import Swal from 'sweetalert2';

import { Paciente } from 'src/app/models/paciente.model';

import { FormularioPacientesService } from '../../../services/formulario-pacientes.service';
import { BusquedasService } from 'src/app/services/busquedas.service';



@Component({
  selector: 'app-lista-pacientes',
  templateUrl: './lista-pacientes.component.html',
  styles: [
  ]
})
export class ListaPacientesComponent implements OnInit {

  public p: number = 1;

  public cargando: boolean = true;
  public pacientes: Paciente[];

  constructor( private pacienteService: FormularioPacientesService,
               private busquedaService: BusquedasService ) { }

  ngOnInit(): void {
    this.cargarPacientes();
  }

  public buscar( termino: string ) {

    if ( termino.length === 0 ) {
      return this.cargarPacientes();
    }

    this.busquedaService.buscar( 'pacientes', termino )
        .subscribe( resp => {
          this.pacientes = resp;
        });
  }

  public cargarPacientes() {
    this.cargando = true;
    this.pacienteService.cargarPacientes()
      .subscribe( pacientes => {
        this.cargando = false;
        this.pacientes = pacientes;
      });
  }

  public borrarPaciente( paciente: Paciente) {
    Swal.fire({
      title: '¿Borrar paciente?',
      text: `Esta a punto de borrar a ${ paciente.nombre } ${ paciente.apellido1 }`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Si, borrarlo'
    }).then((result) => {
      if (result.value) {

        this.pacienteService.borrarPaciente( paciente._id )
          .subscribe( resp => {

            this.cargarPacientes();
            Swal.fire(
              'Paciente borrado',
              `${ paciente.nombre } ${ paciente.apellido1 } fue eliminado correctamente`,
              'success'
            );
          });
      }
    })
  }

}
