import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {

  public menu = [];

  cargarMenu() {
    this.menu = JSON.parse(localStorage.getItem('menu')) || [];
  }

 // menu: any[] = [
 //   {
 //     titulo: 'Dashboard',
 //     icono: 'mdi mdi-gauge',
 //     submenu: [
 //       { titulo: 'Main', url: '/' },
 //       { titulo: 'Gráficas', url: 'grafica1' },
        /*
        { titulo: 'rxjs', url: 'rxjs' },
        { titulo: 'Promesas', url: 'promesas' },
        { titulo: 'ProgressBar', url: 'progress' },
        */
 //     ]
 //   },
 //   {
 //     titulo: 'Mantenimiento',
 //     icono: 'mdi mdi-folder-lock-open',
 //     submenu: [
 //       { titulo: 'Usuarios', url: 'usuarios' },
 //       { titulo: 'Hospitales', url: 'hospitales' },
 //       { titulo: 'Médicos', url: 'medicos' },
 //     ]
 //   },
  //  {
  //    titulo: 'Médico',
  //    icono: 'mdi mdi-account-circle',
  //    submenu: [
  //      { titulo: 'Mis Pacientes', url: 'mis-pacientes' },
   //   ]
  //  },
  //  {
   //   titulo: 'Tecnólogo',
   //   icono: 'mdi mdi-account-circle',
   //   submenu: [
    //    { titulo: 'Exámenes', url: 'examenes' },
    //  ]
  //  },
   // {
   //   titulo: 'Secretaria',
   //   icono: 'mdi mdi-account-circle',
   //   submenu: [
    //    { titulo: 'Formulario', url: 'formulario-paciente' },
    //    { titulo: 'Lista pacientes', url: 'lista-pacientes' },
    //  ]
   // },
  //];

  constructor() { }
}
