import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { CargarMedico } from '../interfaces/cargar-medicos.interface';

import { Medico } from '../models/medico.model';
import { Hospital } from '../models/hospital.model';

const base_url = environment.base_url;


@Injectable({
  providedIn: 'root'
})
export class MedicoService {

  constructor( private http: HttpClient ) { }

  public get token(): string {
    return localStorage.getItem('token') || '';
  }

  public get headers() {
    return {
      headers: {
        'token': this.token
      }
    }
  }


  public cargarMedicos() {

    const url = `${ base_url }/medicos`;
    return this.http.get( url, this.headers )
              .pipe(
                map( (resp: {ok: boolean, medicos: Medico[] }) => resp.medicos )
              );
  }
  

/*  public cargarMedicos(desde: number = 0) {
    const url = `${base_url}/medicos?desde=${desde}`;
    return this.http.get<CargarMedico>(url, this.headers)
      .pipe(
        map(resp => {
          const medicos = resp.medicos.map(
            med => new Medico(med.nombre,med.apellido1, med.apellido2,med.img, med.usuario, med.hospital ,med.mid,med._id),
          // hos => new Hospital(hos.nombre)
          );
          return {
            total: resp.total,
            medicos
          };
        })
      )
  }

  */

  public obtenerMedicoPorId( id: string ) {

    const url = `${ base_url }/medicos/${ id }`;
    return this.http.get( url, this.headers )
              .pipe(
                map( (resp: {ok: boolean, medico: Medico }) => resp.medico )
              );
  }

  public crearMedico( medico: { nombre: string, hospital: string } ) {

    const url = `${ base_url }/medicos`;
    return this.http.post( url, medico, this.headers );
  }

  public actualizarMedico( medico: Medico  ) {
    const url = `${ base_url }/medicos/${ medico.mid }`;
    console.log(url);
    return this.http.put( url, medico, this.headers );
  }

  public borrarMedico( mid: string ) {

    const url = `${ base_url }/medicos/${ mid }`;
    return this.http.delete( url, this.headers );
  }

}
