import { Injectable, EventEmitter } from '@angular/core';
import { environment } from '../../environments/environment.prod';

const base_url = environment.base_url;

@Injectable({
  providedIn: 'root'
})
export class ModalImagenService {

  private _ocultarModal: boolean = true;
  public tipo: 'usuarios' | 'medicos' | 'hospitales' | 'examenes' | 'pdf';
  public id: string;
  public img: string;

  public nuevaImagen: EventEmitter<string> = new EventEmitter<string>();

  public get ocultarModal() {
    return this._ocultarModal;
  }

  public abrirModal(
    tipo: 'usuarios' | 'medicos' | 'hospitales' | 'examenes' | 'pdf',
    id: string,
    img: string = 'no-img'
  ) {
    this._ocultarModal = false;
    this.tipo = tipo;
    this.id = id;
    if (img.includes('https')) {
      this.img = img;
    } else {
      this.img = `${base_url}/upload/${tipo}/${img}`;
    }

  }

  public cerrarModal() {
    this._ocultarModal = true;
    this.refresh();
  }

  public refresh(): void { 
    window.location.reload(); 
  }

  constructor() { }
}
