<section id="wrapper">
    <div class="card-body border">
        <form class="form-horizontal form-material" autocomplete="off" [formGroup]="pacienteForm" (ngSubmit)="validarRut()">
            <div class="form-group row">
                <label for="example-text-input" class="col-2 col-form-label">Nombre</label>
                <div class="col-10">
                    <input class="form-control" type="text" id="example-text-input" formControlName="nombre">
                </div>
            </div>
            <div class="form-group row">
                <label for="example-text-input" class="col-2 col-form-label">Apellido Paterno</label>
                <div class="col-10">
                    <input class="form-control" type="text" id="example-text-input" formControlName="apellido1">
                </div>
            </div>
            <div class="form-group row">
                <label for="example-text-input" class="col-2 col-form-label">Apellido Materno</label>
                <div class="col-10">
                    <input class="form-control" type="text" id="example-text-input" formControlName="apellido2">
                </div>
            </div>
            <div class="form-group row">
                <label for="example-text-input" class="col-2 col-form-label">Correo</label>
                <div class="col-10 center">
                    <input class="form-control" type="email" placeholder="Ingrese correo" formControlName="correo">
                </div>
            </div>
            <div class="form-group row">
                <label for="example-text-input" class="col-2 col-form-label">Rut</label>
                <div class="col-10 center">
                    <input class="form-control" type="text" id="rut" name="rut" required oninput="checkRut(this)" placeholder="Ingrese RUT" formControlName="rut">
                </div>
            </div>
            <!-- 
            <div class="form-group row">
                <label for="example-number-input" class="col-2 col-form-label">Edad</label>
                <div class="col-10">
                    <input class="form-control" type="number" id="example-number-input" formControlName="age">
                </div>
            </div>
            <div class="form-group row">
                <label for="example-datetime-local-input" class="col-2 col-form-label">Fecha y hora</label>
                <div class="col-10">
                    <input class="form-control" type="datetime-local" id="example-datetime-local-input" formControlName="dateTime">
                </div>
            </div>
            -->
            <div class="form-group row">
                <label for="example-date-input" class="col-2 col-form-label">Fecha Nacimiento</label>
                <div class="col-10">
                    <input class="form-control" type="date" id="example-date-input" formControlName="fechaNac">
                </div>
            </div>
            <div class="form-group row">
                <label for="example-tel-input" class="col-2 col-form-label">Telefono</label>
                <div class="col-10">
                    <input class="form-control" type="tel" id="example-tel-input" formControlName="telefono">
                </div>
            </div>

            <div class="form-group row m-b-0 center-button">
                <div class="offset-sm-3 col-sm-9">
                    <button type="submit" [disabled]="pacienteForm.invalid" class="btn waves-effect waves-light btn-block btn-info">
                    <i class="fa fa-save"></i>
                    Guardar
                    </button>
                </div>
            </div>
        </form>
    </div>
</section>