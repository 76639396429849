import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { DatePipe } from '@angular/common'

import { Paciente } from '../../../models/paciente.model';
import { Examen } from '../../../models/examenes.model';


import { FormularioPacientesService } from '../../../services/formulario-pacientes.service';
import { ExamenesService } from '../../../services/examenes.service';

import { MailService } from '../../../services/mail.service';

import { delay } from 'rxjs/operators';
import * as dayjs from 'dayjs';
import { pdf } from '../../../../environments/environment';
// Importar PDFmake 
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { Subscription } from 'rxjs';
import { FileUploadService } from 'src/app/services/file-upload.service';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
const base_pdf = pdf.base_pdf;
const base_pdf2 = pdf.base_pdf2;
const base_pdf3 = pdf.base_pdf3;




@Component({
  selector: 'app-examen-finalizados',
  templateUrl: './examen-finalizados.component.html',
  styleUrls: []
})
export class ExamenFinalizadosComponent implements OnInit {
  //public examenForm: FormGroup;
  public logo = '../../../assets/images/logo-medscan.jpg';
  public date2: String;
  //public examenForm: FormGroup;
  public imagenExamenSeleccionado = null;
  public pacientes: Paciente[] = [];
  public fechaNacPaciente; 
  public edad;

  
  public imagenSubir: File;
  public imgTemp: any = null;
  public examenId;
  private imgSubs: Subscription;

  public pacienteSeleccionado2: Paciente;
  public examenSeleccionado: Examen;
  public pacienteSeleccionado: Paciente;

  public fecha = new Date();

  public mayusape;

  public examenForm: FormGroup = this.fb.group({
    paciente: ['', Validators.required],
    tipoExamen: ['', Validators.required],
    formaPago: ['', Validators.required],
    horaExamen: ['', Validators.required],
    medico: ['', Validators.required],
    usuario: ['', Validators.required],
    estado: ['', Validators.required],
    radioFarmaco: [''],
    observacionesTec: [''],
    observacionesTec2: [''],
    observacionesMed: [''],
    observacionesMed2: [''],
    diagnostico: [' '],
    fechaIngreso: [''],
    horaFormateada: [''],
    emailPaciente: [''],
    nombrePaciente: [''],
    apellido1Paciente: [''],
    apellido2Paciente: [''],
    rutPaciente: [''],
    edadPaciente: [''],
    usuarioNombre: [''],
    infoPaciente: [''],
  });

  constructor(private fb: FormBuilder,
    private pacientesService: FormularioPacientesService,
    private examenService: ExamenesService,
    private mailService: MailService,
    public datepipe: DatePipe,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private fileUploadService: FileUploadService,
    ) { }

  ngOnInit(): void {
    this.activatedRoute.params
      .subscribe(({ id }) => this.cargarExamen(id));

    this.cargarPacientes();

    this.examenForm.get('paciente').valueChanges
      .subscribe(pacienteId => {
        this.pacienteSeleccionado = this.pacientes.find(paciente => paciente._id === pacienteId);
      })

      this.obtenerEdad();
  }

  public obtenerEdad() {
    this.examenForm.get('paciente').valueChanges
    .subscribe(pacienteId => {
      this.pacienteSeleccionado2 = this.pacientes.find(paciente =>{ 
        paciente._id === pacienteId
        if (paciente._id === pacienteId) {
          this.fechaNacPaciente = paciente.fechaNac;
          this.edad = this.calcularEdad(this.fechaNacPaciente);
        }
      });
    })
  }

  public cargarExamen(id: string) {

    if (id === 'nuevo') {
      return;
    }

    this.examenService.obtenerExamenPorId(id)
      .pipe(
        delay(100)
      )
      .subscribe(examen => {
        this.examenId = examen._id;

        if (!examen) {
          return this.router.navigateByUrl(`/dashboard/examenes-finalizados`);
        }

        let { tipoExamen, horaExamen, formaPago, estado, radioFarmaco, observacionesTec, observacionesMed, diagnostico, emailPaciente, paciente: { _id }, observacionesTec2,
              medico, usuario, fechaIngreso, horaFormateada, edadPaciente, nombrePaciente, apellido1Paciente, apellido2Paciente, rutPaciente, medicoNombre, infoPaciente } = examen;
        horaFormateada = dayjs(horaExamen).format('DD-MM-YYYY hh:mm');
        fechaIngreso = dayjs(this.fecha).format('MM/DD/YYYY');
        edadPaciente = this.edad;
        this.examenForm.patchValue({ 
          tipoExamen,
          horaExamen, 
          formaPago, 
          estado, 
          radioFarmaco, 
          observacionesTec, 
          observacionesMed,
          diagnostico,
          paciente: _id,
          medico: examen.medico._id,
          usuario: examen.usuario._id,
          fechaIngreso,
          horaFormateada,
          emailPaciente,
          nombrePaciente,
          apellido1Paciente,
          apellido2Paciente,
          rutPaciente,
          edadPaciente,
          medicoNombre,
          infoPaciente,
          observacionesTec2,
        });
        this.examenSeleccionado = examen;
        this.imagenExamenSeleccionado = examen.img;
        this.mayusape = examen.medico.apellido1.toUpperCase();
      });

  }

  public cargarPacientes() {

    this.pacientesService.cargarPacientes()
      .subscribe((pacientes: Paciente[]) => {
        this.pacientes = pacientes;
      })

  }

  public guardarExamen() {

    if (this.examenSeleccionado) {
      // actualizar
      const data = {
        ...this.examenForm.value,
        _id: this.examenSeleccionado._id
      }
      this.examenService.actualizarExamen(data)
        .subscribe(resp => {
          Swal.fire('Actualizado', `Paciente actualizado correctamente`, 'success');
          this.router.navigateByUrl(`/dashboard/examenes-finalizados`);
        }, (err) => {
          console.log(err);
          Swal.fire('Error', err.error.errors.nombre.msg, 'error');
        }
        )
    }
  }

  public enviarCorreo() {

    if (this.examenSeleccionado) {
      console.log(this.examenSeleccionado);
      // actualizar
      const data = {
        ...this.examenForm.value,
        _id: this.examenSeleccionado._id
      }
      this.mailService.enviarCorreo(this.examenSeleccionado)
        .subscribe(resp => {
          Swal.fire('Correo enviado', `Se envió correo con éxito`, 'success');
          //this.router.navigateByUrl(`/dashboard/examenes-finalizados`);
        }, (err) => {
          console.log(err);
          //Swal.fire('Error', err.error.msg, 'error');
          Swal.fire('Correo enviado', `Se envió correo con éxito`, 'success');
        }
        )
    }
  }


  public myFunction() {
    this.date2 = this.pacienteSeleccionado.fechaNac;
    let fechaNacTransformada = this.datepipe.transform(this.date2, 'dd-MM-yyyy');
    return fechaNacTransformada;
  }

  public myFunction2() {
    this.date2 = this.examenSeleccionado.horaExamen;
    let fechaIngresoTransformada = this.datepipe.transform(this.date2, 'dd-MM-yyyy HH:mm aa');
    return fechaIngresoTransformada;
  }

  public calcularEdad(edad) {
      if(this.fechaNacPaciente){
        let convertirEdad = new Date(this.fechaNacPaciente);
        let diferencia = Math.abs(Date.now() - convertirEdad.getTime());
        edad = Math.floor((diferencia / (1000 * 3600 * 24))/365);
        return edad;
      }
  }


  // Generamos el PDF

  public generatePDF() {
    var docDefinition = {
      header: {
        columns: [
          {
            text: 'Examen Medscan',
            alignment: 'center',
            bold: true,
            fontSize: 20,
            margin: [0, 15, 0, 0],

          }
        ],
      },

      footer: {
        columns: [
          {
            text: 'Todos los derechos reservados a Medscan@2021',
            alignment: 'center',
            bold: true,
            //  italic: true,
            margin: [0, 0, 0, 20]
          }
        ],
      },

      info: {
        title: 'Examen Medscan',
        author: 'Medscan',
        subject: this.examenSeleccionado.tipoExamen,
        keywords: 'El centro de imágenes MEDSCAN-CONCEPCIÓN tiene como misión brindar al Sistema de Salud de Concepción y Sur de Chile, tanto público como privado, medios diagnósticos de alta tecnología basados en imágenes de PET-CT, Medicina Nuclear, Scanner, Resonancia Magnética y Ecografía. ',
        creator: 'Medscan',
        producer: 'Medscan'
      },


      content: [
        {
          columns: [

            {
              widths: 95,
              height: 80,
              image: base_pdf
              ,
              alignment: 'center',
              margin: [0, 10, 0, 10],
            },
            {

              stack: [
                // second column consists of paragraphs
                { text: 'Centro Médico: Ubicado en Paicaví 270, Concepción, Chile', link: 'https://medscan.proyectosfit.cl/ubicacion/' },
                { text: 'Teléfonos: (41) 222 2116 y (41) 223 2014', link: 'https://medscan.proyectosfit.cl/ubicacion/' },
                { text: 'Correo: centroimagenes@medscan.cl', link: 'https://medscan.proyectosfit.cl/ubicacion/' },
              ],
              italics: true,
              bold: true,
              alignment: 'center',
              fontSize: 13,
              margin: [0, 20, 0, 20],
            },
          ]


        },

        { text: 'Datos de Paciente', style: 'header', bold: true, fontSize: 15, alignment: 'center', margin: [0, 0, 0, 15] },

        {
          margin: [10, 0, 0, 0],

          columns: [

            {
              alignment: 'center',
              widths: '*',

              table: {
                headerRows: 1,
                widths: '85%',
                body: [
                  ['Nombre'],
                  [{ text: this.pacienteSeleccionado.nombre, fillColor: '#007cba', fillOpacity: 0.8, bold: true }]
                ],
              }

            },
            {
              alignment: 'center',
              widths: '*',

              table: {
                headerRows: 1,
                widths: '85%',

                body: [
                  ['Apellido Paterno'],
                  [{ text: this.pacienteSeleccionado.apellido1, fillColor: '#007cba', fillOpacity: 0.8, bold: true }]
                ],
              }
            },
            {
              alignment: 'center',
              widths: '*',


              table: {
                headerRows: 1,
                widths: '85%',


                body: [
                  ['Apellido Materno'],
                  [{ text: this.pacienteSeleccionado.apellido2, fillColor: '#007cba', fillOpacity: 0.8, bold: true }]
                ],
              }
            },
          ],
        },

        {
          margin: [10, 10, 0, 10],
          columns: [

            {
              alignment: 'center',
              widths: '*',

              table: {
                headerRows: 1,
                widths: '85%',
                body: [
                  ['Fecha de Nacimiento'],
                  [{ text: this.myFunction(), fillColor: '#007cba', fillOpacity: 0.8, bold: true }]
                ],
              }

            },
            {
              alignment: 'center',
              widths: '*',

              table: {
                headerRows: 1,
                widths: '85%',

                body: [
                  ['Rut'],
                  [{ text: this.pacienteSeleccionado.rut, fillColor: '#007cba', fillOpacity: 0.8, bold: true }]
                ],
              }
            },
            {
              alignment: 'center',
              widths: '*',


              table: {
                headerRows: 1,
                widths: '85%',
                body: [
                  ['Telefono'],
                  [{ text: this.pacienteSeleccionado.telefono, fillColor: '#007cba', fillOpacity: 0.8, bold: true }]
                ],
              }
            },
          ],
        },

        {
          margin: [170, 0, 0, 10],
          columns: [
            {
              alignment: 'center',
              widths: '*',

              table: {
                headerRows: 1,
                widths: '45%',

                body: [
                  ['Correo'],
                  [{ text: this.pacienteSeleccionado.correo, fillColor: '#007cba', fillOpacity: 0.8, bold: true }]
                ],
              }
            },
          ],
        },

        

        { text: 'Datos de Examen', style: 'header', bold: true, fontSize: 15, alignment: 'center', margin: [0, 0, 0, 15] },

        {
          alignment: 'center',
          table: {
            headerRows: 1,
            widths: ['35%', '65%'],

            body: [
              ['Numero de Folio', { text: this.examenSeleccionado._id, fillColor: '#007cba', fillOpacity: 0.8, bold: true }],
              ['Medico Referente', { text: this.examenSeleccionado.medico.nombre+" "+this.examenSeleccionado.medico.apellido1+" "+this.examenSeleccionado.medico.apellido2 ,fillColor: '#007cba', fillOpacity: 0.8, bold: true }],
              ['Tipo de Examen', { text: this.examenSeleccionado.tipoExamen, fillColor: '#007cba', fillOpacity: 0.8, bold: true }],
              //['Fecha de Ingreso', { text: this.myFunction2(), fillColor: '#007cba', fillOpacity: 0.8, bold: true }],
              ['Hora de Examen', { text: this.myFunction2(), fillColor: '#007cba', fillOpacity: 0.8, bold: true }],
              ['Forma de Pago', { text: this.examenSeleccionado.formaPago, fillColor: '#007cba', fillOpacity: 0.8, bold: true }],
              ['Radiofarmaco', { text: this.examenSeleccionado.radioFarmaco, fillColor: '#007cba', fillOpacity: 0.8, bold: true }],
              ['Observacion Tecnologo', { text: this.examenSeleccionado.observacionesTec, fillColor: '#007cba', fillOpacity: 0.8, bold: true }],
              ['Observacion Medico', { text: this.examenSeleccionado.observacionesMed, fillColor: '#007cba', fillOpacity: 0.8, bold: true }],
              ['Diagnostico', { text: this.examenSeleccionado.diagnostico, fillColor: '#007cba', fillOpacity: 0.8, bold: true }],            
            //  ['Estado de Examen', { text: this.examenSeleccionado.estado, fillColor: '#007cba', fillOpacity: 0.8, bold: true }],
            ]
          }
        }
      ],

    };
    this.guardarExamen(),

      pdfMake.createPdf(docDefinition).open();
  }

  public formato1() {

    var docDefinition = {
     
      info: {
        title: 'Examen Medscan',
        author: 'Medscan',
        subject: this.examenSeleccionado.tipoExamen,
        keywords: 'El centro de imágenes MEDSCAN-CONCEPCIÓN tiene como misión brindar al Sistema de Salud de Concepción y Sur de Chile, tanto público como privado, medios diagnósticos de alta tecnología basados en imágenes de PET-CT, Medicina Nuclear, Scanner, Resonancia Magnética y Ecografía. ',
        creator: 'Medscan',
        producer: 'Medscan'
      },

      content: [
        // if you don't need styles, you can use a simple string to define a paragraph
        //'This is a standard paragraph, using default style',
    
        // using a { text: '...' } object lets you set styling properties
    
        // if you set the value of text to an array instead of a string, you'll be able
        // to style any part individually
       // {
            //text: [

              {
                columns: [
                  {
                    widths: '30%',
                    alignment: 'right',
                    margin: [12,0,0,0],
                    stack: [
                      // second column consists of paragraphs
                      { text: 'ID  ',style:[ 'textestilo' ] },
                      { text: 'PACIENTE  ', style:[ 'textestilo' ]},
                      { text: 'RUT  ', style:[ 'textestilo' ] },
                      { text: 'EDAD  ',style:[ 'textestilo' ] },
                      { text: 'EXAMEN  ', style:[ 'textestilo' ] },
                      { text: 'DIAGNOSTICO  ', style:[ 'textestilo' ] },
                      { text: 'FECHA   ', style:[ 'textestilo' ] },
                      { text: 'MEDICO DERIVADOR  ', style:[ 'textestilo' ]},
                    ],
                  },
                  {
                    width:'70%',
                    alignment: 'left',
                    stack: [
                      // second column consists of paragraphs
                      { text: ':  '+this.examenSeleccionado.idHistorica ,style:[ 'textestilo' ]} ,
                      { text: ':  '+this.pacienteSeleccionado.nombre+' '+this.pacienteSeleccionado.apellido1+' '+this.pacienteSeleccionado.apellido2, style:[ 'textestilo' ] },
                      { text: ':  '+this.pacienteSeleccionado.rut, style:[ 'textestilo' ] },
                      { text: ':  '+this.edad+' AÑOS', style:[ 'textestilo' ]},
                      { text: ':  '+this.examenSeleccionado.tipoExamen,style:[ 'textestilo' ]},
                      { text: ':  '+this.examenSeleccionado.diagnostico, style:[ 'textestilo' ]},
                      { text: ':  '+this.myFunction2(), style:[ 'textestilo' ] },
                      { text: ':  DR.'+this.examenSeleccionado.medico.nombre+' '+this.examenSeleccionado.medico.apellido1+' '+this.examenSeleccionado.medico.apellido2, style:[ 'textestilo' ]},
                    ],
                  //  margin: [0, 0, 0, 0],
                  },
                ]
              },
              
              // {
              //    margin: [ 0,20,0,0 ],
              //    text: 'ESTIMADO DOCTOR '+this.mayusape ,style:[ 'tituloestilo' ]
              // },

              // {
              //   margin: [ 0,10,0,0 ],
              //   text: 'El '+this.examenSeleccionado.tipoExamen+' realizado a su paciente ha dado el siguiente resultado.' ,style:[ 'textesito' ]
              // },

              {
                margin: [ 0,10,0,0 ],
                text: 'INFORMACIÓN CLINÍCA: :' ,style:[ 'tituloestilo' ]
              },

              {
                margin: [ 0,10,0,0 ],
                text: this.examenSeleccionado.infoPaciente ,style:[ 'textesito']
              },

              {
                margin: [ 0,10,0,0 ],
                text: 'DESCRIPCIÓN DEL ESTUDIO: :' ,style:[ 'tituloestilo' ]
              },

              {
                margin: [ 0,10,0,0 ],
                text: this.examenSeleccionado.observacionesTec2 ,style:[ 'textesito']
              },


              {
                margin: [ 0,10,0,0 ],
                text: 'HALLAZGOS :' ,style:[ 'tituloestilo' ]
              },

              {
                margin: [ 0,10,0,0 ],
                text: this.examenSeleccionado.observacionesMed ,style:[ 'textesito']
              },

              {
                margin: [ 0,10,0,0 ],
                text: 'IMPRESIÓN DIAGNÓSTICA :' ,style:[ 'tituloestilo' ]
              },

              {
                margin : [ 0,10,0,0 ],
                text: this.examenSeleccionado.observacionesMed2 ,style:[ 'textesito']
              },

              {
                margin: [ 0,30,0,0 ],
                text: 'Atentamente,',style:[ 'textestilo'],alignment: 'left'
              },

              {
                margin : [ 0,10,0,0 ],
                text: this.examenSeleccionado.usuarioNombre ,style:[ 'tituloestilo']
              },
              
/*
              {
                image: this.examenSeleccionado.medico.img,
                alignment: 'center',
                width: '100%'
               },

          
       

          
         
           /* { text: 'PACIENTE    :'+this.pacienteSeleccionado.nombre+' '+this.pacienteSeleccionado.apellido1+' '+this.pacienteSeleccionado.apellido2, style:[ 'textestilo' ]},
            { text: 'RUT    :'+this.pacienteSeleccionado.rut+'    ID: '+this.examenSeleccionado._id, style:[ 'textestilo' ] },
            { text: 'EDAD    : MIEDAD',style:[ 'textestilo' ] },
            { text: 'EXAMEN    :'+this.examenSeleccionado.tipoExamen, style:[ 'textestilo' ] },
            { text: 'DIAGNOSTICO    :'+this.examenSeleccionado.diagnostico, style:[ 'textestilo' ] },
            { text: 'FECHA    :'+this.myFunction2(), style:[ 'textestilo' ] },
            { text: 'MEDICO DERIVADOR    : DR.'+this.examenSeleccionado.medico.nombre+' '+this.examenSeleccionado.medico.apellido1+' '+this.examenSeleccionado.medico.apellido2, style:[ 'textestilo' ]},
           // ]
        //},
        {
        
          margin: [0, 20, 0, 0],
          text: [
            { text: 'ESTIMADO DOCTOR '+this.examenSeleccionado.medico.apellido1, style:[ 'tituloestilo' ] },
          ]
        }
        */
      ],

      styles: {
        textestilo: {
          fontSize: 15,
          alignment: 'justify',
          bold: true,
        },
        textesito:{
          fontSize: 15,
          alignment: 'justify',
        },
        tituloestilo: {
          bold: true,
          alignment: 'center',
          fontSize: 18,
        }
      }
    };
    
    pdfMake.createPdf(docDefinition).open();

  }

  public formato2() {

    var docDefinition = {
     
      info: {
        title: 'Examen Medscan',
        author: 'Medscan',
        subject: this.examenSeleccionado.tipoExamen,
        keywords: 'El centro de imágenes MEDSCAN-CONCEPCIÓN tiene como misión brindar al Sistema de Salud de Concepción y Sur de Chile, tanto público como privado, medios diagnósticos de alta tecnología basados en imágenes de PET-CT, Medicina Nuclear, Scanner, Resonancia Magnética y Ecografía. ',
        creator: 'Medscan',
        producer: 'Medscan'
      },

      content: [
        // if you don't need styles, you can use a simple string to define a paragraph
        //'This is a standard paragraph, using default style',
    
        // using a { text: '...' } object lets you set styling properties
    
        // if you set the value of text to an array instead of a string, you'll be able
        // to style any part individually
       // {
            //text: [

            {
              image: base_pdf2,
              width: '500',
              margin: [0,0,0,12],
            },

              {
                columns: [
                  {
                    widths: '30%',
                    alignment: 'right',
                    margin: [12,0,0,0],
                    stack: [
                      // second column consists of paragraphs
                      { text: 'ID  ',style:[ 'textestilo' ] },
                      { text: 'PACIENTE  ', style:[ 'textestilo' ]},
                      { text: 'RUT  ', style:[ 'textestilo' ] },
                      { text: 'EDAD  ',style:[ 'textestilo' ] },
                      { text: 'EXAMEN  ', style:[ 'textestilo' ] },
                      { text: 'DIAGNOSTICO  ', style:[ 'textestilo' ] },
                      { text: 'FECHA   ', style:[ 'textestilo' ] },
                      { text: 'MEDICO DERIVADOR  ', style:[ 'textestilo' ]},
             ],
                  },
                  {
                    width:'70%',
                    alignment: 'left',
                    stack: [
                      // second column consists of paragraphs
                      { text: ':  '+this.examenSeleccionado._id ,style:[ 'textestilo' ]} ,
                      { text: ':  '+this.pacienteSeleccionado.nombre+' '+this.pacienteSeleccionado.apellido1+' '+this.pacienteSeleccionado.apellido2, style:[ 'textestilo' ] },
                      { text: ':  '+this.pacienteSeleccionado.rut, style:[ 'textestilo' ] },
                      { text: ':  '+this.edad+' AÑOS', style:[ 'textestilo' ]},
                      { text: ':  '+this.examenSeleccionado.tipoExamen,style:[ 'textestilo' ]},
                      { text: ':  '+this.examenSeleccionado.diagnostico, style:[ 'textestilo' ]},
                      { text: ':  '+this.myFunction2(), style:[ 'textestilo' ] },
                      { text: ':  DR.'+this.examenSeleccionado.medico.nombre+' '+this.examenSeleccionado.medico.apellido1+' '+this.examenSeleccionado.medico.apellido2, style:[ 'textestilo' ]},
                    ],
                  //  margin: [0, 0, 0, 0],
                  },
                ]
              },
              
              {
                 margin: [ 0,20,0,0 ],
                 text: 'ESTIMADO DOCTOR '+this.mayusape ,style:[ 'tituloestilo' ]
              },

              {
                margin: [ 0,10,0,0 ],
                text: 'INFORMACIÓN CLINÍCA :' ,style:[ 'tituloestilo' ]
              },

              {
                margin: [ 0,10,0,0 ],
                text: 'El '+this.examenSeleccionado.tipoExamen+' realizado a su paciente ha dado el siguiente resultado.' ,style:[ 'textestilo' ]
              },

              {
                margin: [ 0,10,0,0 ],
                text: 'DESCRIPCIÓN DEL ESTUDIO :' ,style:[ 'tituloestilo' ]
              },

              {
                margin: [ 0,10,0,0 ],
                text: this.examenSeleccionado.observacionesMed ,style:[ 'textestilo'],alignment: 'center'
              },

              {
                margin: [ 0,10,0,0 ],
                text: 'HALLAZGOS :' ,style:[ 'tituloestilo' ]
              },

              {
                margin: [ 0,10,0,0 ],
                text: this.examenSeleccionado.observacionesTec ,style:[ 'textestilo'],alignment: 'center'
              },

              {
                margin: [ 0,10,0,0 ],
                text: 'IMPRESIÓN DIAGNÓSTICA :' ,style:[ 'tituloestilo' ]
              },

              {
                margin : [ 0,10,0,0 ],
                text: this.examenSeleccionado.observacionesMed ,style:[ 'textestilo'],alignment: 'center'
              },

              {
                margin: [ 0,10,0,0 ],
                text: 'Atentamente,',style:[ 'textestilo'],alignment: 'left'
              },
/*
              {
                image: this.examenSeleccionado.medico.img,
                alignment: 'center',
                width: '100%'
               },

          */
       

          
         
           /* { text: 'PACIENTE    :'+this.pacienteSeleccionado.nombre+' '+this.pacienteSeleccionado.apellido1+' '+this.pacienteSeleccionado.apellido2, style:[ 'textestilo' ]},
            { text: 'RUT    :'+this.pacienteSeleccionado.rut+'    ID: '+this.examenSeleccionado._id, style:[ 'textestilo' ] },
            { text: 'EDAD    : MIEDAD',style:[ 'textestilo' ] },
            { text: 'EXAMEN    :'+this.examenSeleccionado.tipoExamen, style:[ 'textestilo' ] },
            { text: 'DIAGNOSTICO    :'+this.examenSeleccionado.diagnostico, style:[ 'textestilo' ] },
            { text: 'FECHA    :'+this.myFunction2(), style:[ 'textestilo' ] },
            { text: 'MEDICO DERIVADOR    : DR.'+this.examenSeleccionado.medico.nombre+' '+this.examenSeleccionado.medico.apellido1+' '+this.examenSeleccionado.medico.apellido2, style:[ 'textestilo' ]},
           // ]
        //},
        {
        
          margin: [0, 20, 0, 0],
          text: [
            { text: 'ESTIMADO DOCTOR '+this.examenSeleccionado.medico.apellido1, style:[ 'tituloestilo' ] },
          ]
        }
        */
      ],

      styles: {
        textestilo: {
          fontSize: 15,
          alignment: 'justify',
          bold: true,
        },
        tituloestilo: {
          bold: true,
          alignment: 'center',
          fontSize: 18,
        }
      }
    };
    
    pdfMake.createPdf(docDefinition).open();

  }

  public formato3() {
    var docDefinition = {
     
      info: {
        title: 'Examen Medscan',
        author: 'Medscan',
        subject: this.examenSeleccionado.tipoExamen,
        keywords: 'El centro de imágenes MEDSCAN-CONCEPCIÓN tiene como misión brindar al Sistema de Salud de Concepción y Sur de Chile, tanto público como privado, medios diagnósticos de alta tecnología basados en imágenes de PET-CT, Medicina Nuclear, Scanner, Resonancia Magnética y Ecografía. ',
        creator: 'Medscan',
        producer: 'Medscan'
      },

      content: [
        // if you don't need styles, you can use a simple string to define a paragraph
        //'This is a standard paragraph, using default style',
    
        // using a { text: '...' } object lets you set styling properties
    
        // if you set the value of text to an array instead of a string, you'll be able
        // to style any part individually
       // {
            //text: [

            {
              image: base_pdf2,
              width: '500',
              margin: [0,0,0,12],
            },

              {
                columns: [
                  {
                    widths: '30%',
                    alignment: 'right',
                    margin: [12,0,0,0],
                    stack: [
                      // second column consists of paragraphs
                      { text: 'ID  ',style:[ 'textestilo' ] },
                      { text: 'PACIENTE  ', style:[ 'textestilo' ]},
                      { text: 'RUT  ', style:[ 'textestilo' ] },
                      { text: 'EDAD  ',style:[ 'textestilo' ] },
                      { text: 'EXAMEN  ', style:[ 'textestilo' ] },
                      { text: 'DIAGNOSTICO  ', style:[ 'textestilo' ] },
                      { text: 'FECHA   ', style:[ 'textestilo' ] },
                      { text: 'MEDICO DERIVADOR  ', style:[ 'textestilo' ]},
             ],
                  },
                  {
                    width:'70%',
                    alignment: 'left',
                    stack: [
                      // second column consists of paragraphs
                      { text: ':  '+this.examenSeleccionado._id ,style:[ 'textestilo' ]} ,
                      { text: ':  '+this.pacienteSeleccionado.nombre+' '+this.pacienteSeleccionado.apellido1+' '+this.pacienteSeleccionado.apellido2, style:[ 'textestilo' ] },
                      { text: ':  '+this.pacienteSeleccionado.rut, style:[ 'textestilo' ] },
                      { text: ':  '+this.edad+' AÑOS', style:[ 'textestilo' ]},
                      { text: ':  '+this.examenSeleccionado.tipoExamen,style:[ 'textestilo' ]},
                      { text: ':  '+this.examenSeleccionado.diagnostico, style:[ 'textestilo' ]},
                      { text: ':  '+this.myFunction2(), style:[ 'textestilo' ] },
                      { text: ':  DR.'+this.examenSeleccionado.medico.nombre+' '+this.examenSeleccionado.medico.apellido1+' '+this.examenSeleccionado.medico.apellido2, style:[ 'textestilo' ]},
                    ],
                  //  margin: [0, 0, 0, 0],
                  },
                ]
              },
              
              {
                 margin: [ 0,20,0,0 ],
                 text: 'ESTIMADO DOCTOR '+this.mayusape ,style:[ 'tituloestilo' ]
              },

              {
                margin: [ 0,10,0,0 ],
                text: 'INFORMACIÓN CLINÍCA :' ,style:[ 'tituloestilo' ]
              },


              {
                margin: [ 0,10,0,0 ],
                text: 'El '+this.examenSeleccionado.tipoExamen+' realizado a su paciente ha dado el siguiente resultado.' ,style:[ 'textestilo' ]
              },

              {
                image: base_pdf3,
                width: 350,
                alignment: 'center'
              },

              {
                margin: [ 0,10,0,0 ],
                text: 'DESCRIPCIÓN DEL ESTUDIO :' ,style:[ 'tituloestilo' ]
              },

              {
                margin: [ 0,10,0,0 ],
                text: this.examenSeleccionado.observacionesMed ,style:[ 'textestilo'],alignment: 'center'
              },

              {
                margin: [ 0,10,0,0 ],
                text: 'HALLAZGOS :' ,style:[ 'tituloestilo' ]
              },

              {
                image: base_pdf3,
                width: 350,
                alignment: 'center'
              },

              {
                margin: [ 0,10,0,0 ],
                text: this.examenSeleccionado.observacionesTec ,style:[ 'textestilo'],alignment: 'center'
              },

              {
                margin: [ 0,10,0,0 ],
                text: 'IMPRESIÓN DIAGNÓSTICA :' ,style:[ 'tituloestilo' ]
              },

              {
                image: base_pdf3,
                width: 350,
                alignment: 'center'
              },

              {
                margin : [ 0,10,0,0 ],
                text: this.examenSeleccionado.observacionesMed ,style:[ 'textestilo'],alignment: 'center'
              },

              {
                margin: [ 0,10,0,0 ],
                text: 'Atentamente,',style:[ 'textestilo'],alignment: 'left'
              },
/*
              {
                image: this.examenSeleccionado.medico.img,
                alignment: 'center',
                width: '100%'
               },

          */
       

          
         
           /* { text: 'PACIENTE    :'+this.pacienteSeleccionado.nombre+' '+this.pacienteSeleccionado.apellido1+' '+this.pacienteSeleccionado.apellido2, style:[ 'textestilo' ]},
            { text: 'RUT    :'+this.pacienteSeleccionado.rut+'    ID: '+this.examenSeleccionado._id, style:[ 'textestilo' ] },
            { text: 'EDAD    : MIEDAD',style:[ 'textestilo' ] },
            { text: 'EXAMEN    :'+this.examenSeleccionado.tipoExamen, style:[ 'textestilo' ] },
            { text: 'DIAGNOSTICO    :'+this.examenSeleccionado.diagnostico, style:[ 'textestilo' ] },
            { text: 'FECHA    :'+this.myFunction2(), style:[ 'textestilo' ] },
            { text: 'MEDICO DERIVADOR    : DR.'+this.examenSeleccionado.medico.nombre+' '+this.examenSeleccionado.medico.apellido1+' '+this.examenSeleccionado.medico.apellido2, style:[ 'textestilo' ]},
           // ]
        //},
        {
        
          margin: [0, 20, 0, 0],
          text: [
            { text: 'ESTIMADO DOCTOR '+this.examenSeleccionado.medico.apellido1, style:[ 'tituloestilo' ] },
          ]
        }
        */
      ],

      styles: {
        textestilo: {
          fontSize: 15,
          alignment: 'left',
          bold: true,
        },
        tituloestilo: {
          bold: true,
          alignment: 'center',
          fontSize: 18,
        }
      }
    };
    
    pdfMake.createPdf(docDefinition).open();

  }

  public formato4() {

    var docDefinition = {
     
      info: {
        title: 'Examen Medscan',
        author: 'Medscan',
        subject: this.examenSeleccionado.tipoExamen,
        keywords: 'El centro de imágenes MEDSCAN-CONCEPCIÓN tiene como misión brindar al Sistema de Salud de Concepción y Sur de Chile, tanto público como privado, medios diagnósticos de alta tecnología basados en imágenes de PET-CT, Medicina Nuclear, Scanner, Resonancia Magnética y Ecografía. ',
        creator: 'Medscan',
        producer: 'Medscan'
      },

      content: [
        // if you don't need styles, you can use a simple string to define a paragraph
        //'This is a standard paragraph, using default style',
    
        // using a { text: '...' } object lets you set styling properties
    
        // if you set the value of text to an array instead of a string, you'll be able
        // to style any part individually
       // {
            //text: [

            {
              image: base_pdf2,
              width: '500',
              margin: [0,0,0,12],
            },

              {
                columns: [
                  {
                    widths: '30%',
                    alignment: 'right',
                    margin: [12,0,0,0],
                    stack: [
                      // second column consists of paragraphs
                      { text: 'ID  ',style:[ 'textestilo' ] },
                      { text: 'PACIENTE  ', style:[ 'textestilo' ]},
                      { text: 'RUT  ', style:[ 'textestilo' ] },
                      { text: 'EDAD  ',style:[ 'textestilo' ] },
                      { text: 'EXAMEN  ', style:[ 'textestilo' ] },
                      { text: 'DIAGNOSTICO  ', style:[ 'textestilo' ] },
                      { text: 'FECHA   ', style:[ 'textestilo' ] },
                      { text: 'MEDICO DERIVADOR  ', style:[ 'textestilo' ]},
             ],
                  },
                  {
                    width:'70%',
                    alignment: 'left',
                    stack: [
                      // second column consists of paragraphs
                      { text: ':  '+this.examenSeleccionado._id ,style:[ 'textestilo' ]} ,
                      { text: ':  '+this.pacienteSeleccionado.nombre+' '+this.pacienteSeleccionado.apellido1+' '+this.pacienteSeleccionado.apellido2, style:[ 'textestilo' ] },
                      { text: ':  '+this.pacienteSeleccionado.rut, style:[ 'textestilo' ] },
                      { text: ':  '+this.edad+' AÑOS', style:[ 'textestilo' ]},
                      { text: ':  '+this.examenSeleccionado.tipoExamen,style:[ 'textestilo' ]},
                      { text: ':  '+this.examenSeleccionado.diagnostico, style:[ 'textestilo' ]},
                      { text: ':  '+this.myFunction2(), style:[ 'textestilo' ] },
                      { text: ':  DR.'+this.examenSeleccionado.medico.nombre+' '+this.examenSeleccionado.medico.apellido1+' '+this.examenSeleccionado.medico.apellido2, style:[ 'textestilo' ]},
                    ],
                  //  margin: [0, 0, 0, 0],
                  },
                ]
              },
              
              {
                 margin: [ 0,20,0,0 ],
                 text: 'ESTIMADO DOCTOR '+this.mayusape ,style:[ 'tituloestilo' ]
              },

              {
                margin: [ 0,10,0,0 ],
                text: 'La '+this.examenSeleccionado.tipoExamen+'con '+this.examenSeleccionado.radioFarmaco ,style:[ 'textestilo' ]
              },

              {
                margin: [ 0,10,0,0 ],
                text: ' PROYECCIONES : '+this.examenSeleccionado.observacionesTec ,style:[ 'tituloestilo' ]
              },

              {
                margin: [ 0,10,0,0 ],
                text: this.examenSeleccionado.observacionesTec ,style:[ 'textestilo'],alignment: 'center'
              },

              {
                margin: [ 0,10,0,0 ],
                text: 'IMPRESION : '+this.examenSeleccionado.observacionesMed ,style:[ 'tituloestilo' ]
              },

              {
                margin : [ 0,10,0,0 ],
                text: this.examenSeleccionado.observacionesMed ,style:[ 'textestilo'],alignment: 'center'
              },

              {
                margin: [ 0,10,0,0 ],
                text: 'Atentamente,',style:[ 'textestilo'],alignment: 'left'
              },
/*
              {
                image: this.examenSeleccionado.medico.img,
                alignment: 'center',
                width: '100%'
               },

          */
       

          
         
           /* { text: 'PACIENTE    :'+this.pacienteSeleccionado.nombre+' '+this.pacienteSeleccionado.apellido1+' '+this.pacienteSeleccionado.apellido2, style:[ 'textestilo' ]},
            { text: 'RUT    :'+this.pacienteSeleccionado.rut+'    ID: '+this.examenSeleccionado._id, style:[ 'textestilo' ] },
            { text: 'EDAD    : MIEDAD',style:[ 'textestilo' ] },
            { text: 'EXAMEN    :'+this.examenSeleccionado.tipoExamen, style:[ 'textestilo' ] },
            { text: 'DIAGNOSTICO    :'+this.examenSeleccionado.diagnostico, style:[ 'textestilo' ] },
            { text: 'FECHA    :'+this.myFunction2(), style:[ 'textestilo' ] },
            { text: 'MEDICO DERIVADOR    : DR.'+this.examenSeleccionado.medico.nombre+' '+this.examenSeleccionado.medico.apellido1+' '+this.examenSeleccionado.medico.apellido2, style:[ 'textestilo' ]},
           // ]
        //},
        {
        
          margin: [0, 20, 0, 0],
          text: [
            { text: 'ESTIMADO DOCTOR '+this.examenSeleccionado.medico.apellido1, style:[ 'tituloestilo' ] },
          ]
        }
        */
      ],

      styles: {
        textestilo: {
          fontSize: 15,
          alignment: 'left',
          bold: true,
        },
        tituloestilo: {
          bold: true,
          alignment: 'center',
          fontSize: 18,
        }
      }
    };
    
    pdfMake.createPdf(docDefinition).open();
  }
}
