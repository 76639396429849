import { Injectable, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment.prod';

import { map } from 'rxjs/operators';

import { Examen } from '../models/examenes.model'


const base_url = environment.base_url;

@Injectable({
  providedIn: 'root'
})
export class ExamenesService {

  constructor(
    private http: HttpClient,
  ) { }

  

  public get token(): string {
    return localStorage.getItem('token') || '';
  }

  public get headers() {
    return {
      headers: {
        'token': this.token
      }
    }
  }


  public cargarExamenes() {

    const url = `${ base_url }/examenes`;
    return this.http.get( url, this.headers )
              .pipe(
                map( (resp: {ok: boolean, examenes: Examen[] }) => resp.examenes )
              );
  }

  public obtenerExamenPorId( id: string ) {

    const url = `${ base_url }/examenes/${ id }`;
    return this.http.get( url, this.headers )
              .pipe(
                map( (resp: {ok: boolean, examen: Examen }) => resp.examen )
              );
  }

  public crearExamen( examen: { nombre: string, paciente: string, usuario:string, medico: string } ) {

    const url = `${ base_url }/examenes`;
    return this.http.post( url, examen, this.headers );
  }

  public actualizarExamen( examen: Examen  ) {

    const url = `${ base_url }/examenes/${ examen._id }`;
    return this.http.put( url, examen, this.headers );
  }

  public borrarExamen( _id: string ) {

    const url = `${ base_url }/examenes/${ _id }`;
    return this.http.delete( url, this.headers );
  }
}
