import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';

import { Paciente } from '../../../models/paciente.model';
import { Examen } from '../../../models/examenes.model';
import { Usuario } from '../../../models/usuario.model';
import { Medico } from '../../../models/medico.model';

import { FormularioPacientesService } from '../../../services/formulario-pacientes.service';
import { ExamenesService } from '../../../services/examenes.service';
import { MedicoService } from '../../../services/medicos.service';

import { delay, timestamp } from 'rxjs/operators';
import * as dayjs from 'dayjs';
import { DatePipe } from '@angular/common';
import { UsuarioService } from 'src/app/services/usuario.service';

@Component({
  selector: 'app-examen-secretaria',
  templateUrl: './examen-secretaria.component.html',
  styles: [
  ]
})
export class ExamenSecretariaComponent implements OnInit {

  public pacientes: Paciente[] = [];
  public medicos: Medico[] = [];
  public examenSeleccionado: Examen;
  public pacienteSeleccionado2: Paciente;
  public pacienteSeleccionado: Paciente;
  public usuarios: Usuario[] = [];
  public usuariosTemp: Usuario[] = [];
  public totalUsuarios: number = 0;
  public usuariosMedicos: Usuario[] = [];

  public fecha = new Date();
  public desde: number = 0;
  public fechaNacPaciente;
  public edad;

  //Arreglos
  
  public examenes = [
    {id: 1,nombre: 'Cintigrafía evaluación infecciones (INFECTON)'},
    {id: 2, nombre: 'Cintigrafía evaluación infecciones (UBIQUICIDINA)'},
    {id: 3, nombre: 'Cintigrafía Osea completa planar o médula ósea'},
    {id: 4, nombre: 'Cintigrafía Osea Trifásica'},
    {id: 5, nombre: 'Cintigrafía pulmonar perfusión'},
    {id: 6, nombre: 'Cintigrafía y estudio aspiración pulmonar'},
    {id: 7, nombre: 'Cintigrama de glándulas salivales o dacriocintigrafía)'},
    {id: 8, nombre: 'Cintigrama de Tiroides con Tecnecio 99m.'},
    {id: 9, nombre: 'Cintigrama glándulas paratiroides (No incluye MIBI)'},
    {id: 10, nombre: 'Cintigrama renal con  DMSA'},
    {id: 11, nombre: 'Cintigrama vesícula y vía biliar'},
    {id: 12, nombre: 'Cistografía isotópica directa '},
    {id: 13, nombre: 'CT ABDOMEN'},
    {id: 14, nombre: 'CT ABDOMEN-PELVIS CC'},
    {id: 15, nombre: 'CT ANGULO PONTO CEREBELOSO'},
    {id: 16, nombre: 'CT CADA ESPACIO ADICIONAL'},
    {id: 17, nombre: 'CT CEREBRO'},
    {id: 18, nombre: 'CT COLUMNA CERVICAL'},
    {id: 19, nombre: 'CT COLUMNA DORSAL O LUMBAR'},
    {id: 20, nombre: 'CT CORTES CORONALES'},
    {id: 21, nombre: 'CT CUELLO, PARTES BLANDAS'},
    {id: 22, nombre: 'CT EXTREMIDADES, ESTUDIO LOCALIZADO (30 CORTES 2-4 MM.)'},
    {id: 23, nombre: 'CT ORBITAS MAXILOFACIAL'},
    {id: 24, nombre: 'CT PELVIS (28 CORTES, 8-10 MM.)'},
    {id: 25, nombre: 'CT SILLA TURCA'},
    {id: 26, nombre: 'CT TEMPORAL-OIDO'},
    {id: 27, nombre: 'CT TORAX TOTAL'},
    {id: 28, nombre: 'CT TORAX-ABDOMEN-PELVIS CC'},
    {id: 29, nombre: 'Detección de sitio de sangramiento digestivo con GR. Marcados'},
    {id: 30, nombre: 'Detección divertículo de meckel'},
    {id: 31, nombre: 'Estudio de glándulas mamarias (mamocintigrafia)(No Incluye MIBI)'},
    {id: 32, nombre: 'Estudio dinámico renal con Tc99 DTPA'},
    {id: 33, nombre: 'Estudio dinámico renal con Tc99 MAG3'},
    {id: 34, nombre: 'Estudio dinámico sistema nervioso (Radiocisternografia, Fístula LCR.,'},
    {id: 35, nombre: 'Estudio motilidad esofágica y/o reflujo gastroesofagico'},
    {id: 36, nombre: 'Linfocintigrafia isotópica (No incluye procedimiento)'},
    {id: 37, nombre: 'PET-CT (Incluye contraste y radiofármaco) FDG'},
    {id: 38, nombre: 'PET-CT (Incluye contraste y radiofármaco) PSMA'},
    {id: 39, nombre: 'Pool Sanguineo Spect'},
    {id: 40, nombre: 'Pool Sanguineo, arteriografía isotópica c/u'},
    {id: 41, nombre: 'Spect Cardiaco Stress y reposo MIBI-DIPIRAMOL'},
    {id: 42, nombre: 'Spect Cardiaco Stress y reposo MIBI-Esfuerzo'},
    {id: 43, nombre: 'Spect cerebral de perfusión (No incluye radiofarmaco)'},
    {id: 44, nombre: 'Spect hepatoesplenico, evaluación hemangioma o hiperplasia nod, focal'},
    {id: 45, nombre: 'Spect tomografía por emisión foton único, cualquier órgano'},
    {id: 46, nombre: 'Vaciamiento gástrico líquido o sólido'},
    {id: 47, nombre: 'Ventriculografía cardiaca Isotópica'},
  ];

  public pagos = [
    {id: 1,nombre: 'FONASA'},
    {id: 2, nombre: 'MAS VIDA (NUCLEAR)'},
    {id: 3, nombre: 'BANMEDICA'},
    {id: 4, nombre: 'VIDA 3'},
    {id: 5, nombre: 'PARTICULAR'},
    {id: 6, nombre: 'CONV.HOSP.Los Angeles'},
    {id: 7, nombre: 'CONV.HOSP.Victoria'},
    {id: 8, nombre: 'MAS VIDA (PETSCAN)'},
    {id: 9, nombre: 'CONV.HOSP REGIONAL CONCEPCION'},
    {id: 10, nombre: 'HOSP.HIGUERAS'},
    {id: 11, nombre: 'HOSP. LEBU'},
    {id: 12, nombre: 'HOSP. CHILLAN'},
    {id: 13, nombre: 'CUC'},
    {id: 14, nombre: 'CONV. HOSP NAVAL TALCAHUANO'},
    {id: 15, nombre: 'HOSP. TALCA'},
    {id: 16, nombre: 'CONV. ICOS TEMUCO'},
  ];


  public examenForm: FormGroup = this.fb.group({
    paciente: ['', Validators.required],
    tipoExamen: ['', Validators.required],
    medico: ['', Validators.required],
    usuario: ['', Validators.required],
    formaPago: ['', Validators.required],
    horaExamen: ['', Validators.required],
    estado: ['Revision_Tecnologo', Validators.required],
    emailPaciente: [' ', Validators.required],
    nombrePaciente: [' ', Validators.required],
    apellido1Paciente: [' ', Validators.required],
    apellido2Paciente: [' ', Validators.required],
    rutPaciente: [' ', Validators.required],
    edadPaciente: [' ', Validators.required],
    medicoNombre: [' ', Validators.required],
    usuarioNombre: [' ', Validators.required],
    fechaNacPaciente: [' ', Validators.required],
  });

  constructor(private fb: FormBuilder,
    private usuarioService: UsuarioService,
    private medicoService: MedicoService,
    private pacientesService: FormularioPacientesService,
    private examenService: ExamenesService,
    private router: Router,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.cargarUsuarios();
    this.cargarMedicos();
    this.activatedRoute.params
      .subscribe(({ id }) => this.cargarExamen(id));

    this.cargarPacientes();

    this.examenForm.get('paciente').valueChanges
      .subscribe(pacienteId => {
        this.pacienteSeleccionado = this.pacientes.find(paciente => paciente._id === pacienteId);
      })
  }

  public calcularEdad(edad) {
    if(this.fechaNacPaciente){
      let convertirEdad = new Date(this.fechaNacPaciente);
      let diferencia = Math.abs(Date.now() - convertirEdad.getTime());
      edad = Math.floor((diferencia / (1000 * 3600 * 24))/365);
      return edad;
    }
  }

  public obtenerEdad() {
    this.examenForm.get('paciente').valueChanges
    .subscribe(pacienteId => {
      this.pacienteSeleccionado2 = this.pacientes.find(paciente =>{ 
        paciente._id === pacienteId
        if (paciente._id === pacienteId) {
          this.fechaNacPaciente = paciente.fechaNac;
          this.edad = this.calcularEdad(this.fechaNacPaciente);
        }
      });
    })
  }

  public cargarExamen(id: string) {

    if (id === 'nuevo') {
      return;
    }

    this.examenService.obtenerExamenPorId(id)
      .pipe(
        delay(100)
      )
      .subscribe(examen => {

        if (!examen) {
          return this.router.navigateByUrl(`/dashboard/examenes-secretaria`);
        }

        let { tipoExamen, fechaIngreso, formaPago, estado, horaExamen, paciente: { _id }, emailPaciente, nombrePaciente, apellido1Paciente, apellido2Paciente, rutPaciente, medicoNombre, edadPaciente} = examen;
        this.examenSeleccionado = examen;
        this.examenForm.setValue({ tipoExamen, fechaIngreso, formaPago, estado, horaExamen, paciente: _id, emailPaciente,  nombrePaciente, apellido1Paciente, apellido2Paciente, rutPaciente, medicoNombre, edadPaciente});
      });
  }

  public cargarPacientes() {

    this.pacientesService.cargarPacientes()
      .subscribe((pacientes: Examen[]) => {
        this.pacientes = pacientes;
      })

  }

  public guardarExamen() {

    if (this.examenSeleccionado) {
      // actualizar
      const data = {
        ...this.examenForm.value,
        _id: this.examenSeleccionado._id
      }
      this.examenService.actualizarExamen(data)
        .subscribe(resp => {
          Swal.fire('Actualizado', `Examen actualizado correctamente`, 'success');
          this.router.navigateByUrl(`/dashboard/examenes-secretaria`);
        }, (err) => {
          console.log(err);
          Swal.fire('Error', err.error.errors.nombre.msg, 'error');
        }
        )


    } else {
      // crear

      this.examenService.crearExamen(this.examenForm.value)
        .subscribe((resp: any) => {
          Swal.fire('Creado', `Examen creado correctamente`, 'success');
          this.router.navigateByUrl(`/dashboard/lista-pacientes`)
        }, (err) => {
          console.log(err);
          //Swal.fire('Error', err.error.errors.nombre.msg, 'error');
          Swal.fire('Error', err.error.msg, 'error');
        },
        )
    }
  }

  public cargarUsuarios() {
    this.usuarioService.cargarUsuarios()
      .subscribe(( usuarios ) => {
        this.usuarios = usuarios;
        this.usuariosMedicos = usuarios.filter(rol => rol.role === 'MEDIC_ROLE');
        this.usuariosTemp = usuarios;
      });
  }

  public cargarMedicos() {
    this.medicoService.cargarMedicos()
      .subscribe( medicos => {
        this.medicos = medicos;
      });
  }

}
