<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<section id="wrapper">
    <div class="login-register" style="background-image:url(../assets/images/background/login-register-medscan.jpg);">
        <div class="login-box card">
            <div class="card-body border">
                <form class="form-horizontal form-material" id="loginform" autocomplete="off" [formGroup]="registerForm" (ngSubmit)="crearUsuario()">

                    <a href="javascript:void(0)" class="text-center db"><img src="../assets/images/logo-medscan.jpg" alt="Home" /><br/></a>
                    <h3 class="box-title m-t-40 m-b-0">Regístrate ahora</h3><small>Crea tu cuenta</small>
                    <div class="form-group m-t-20">
                        <div class="col-xs-12">
                            <input class="form-control" type="text" placeholder="Nombre" formControlName="nombre">
                        </div>
                    </div>
                    <div class="form-group ">
                        <div class="col-xs-12">
                            <input class="form-control" type="text" placeholder="Correo" formControlName="email">
                        </div>
                    </div>
                    <div class="form-group ">
                        <div class="col-xs-12">
                            <input class="form-control" type="password" placeholder="Contraseña" formControlName="password">
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="col-xs-12">
                            <input class="form-control" type="password" placeholder="Confirmar contraseña" formControlName="password2">
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-md-12">
                            <div class="checkbox checkbox-primary p-t-0">

                                <input id="checkbox-signup" type="checkbox" formControlName="terminos">

                                <label for="checkbox-signup">Estoy de acuerdo con todos los <a href="#">Terminos</a></label>
                            </div>
                        </div>
                    </div>

                    <div class="col-xs-12 col-sm-12 col-md-12 m-t-10 mb-4 text-center">
                        <ngx-recaptcha2 #captchaElem [siteKey]="siteKey" formControlName="recaptcha">
                        </ngx-recaptcha2>
                    </div>

                    <div class="row">
                        <div class="col text-danger">

                            <p *ngIf="campoNoValido('nombre')">* El nombre es obligatorio</p>
                            <p *ngIf="campoNoValido('email')">* El email es obligatorio y tiene que ser válido</p>
                            <p *ngIf="contrasenasNoValidas()">* Las contraseñas deben de ser iguales</p>
                            <p *ngIf="aceptaTerminos()">* Debe de aceptar los términos de uso</p>
                            <p *ngIf="aceptaCaptcha()">* Debe realizar el captcha No soy un robot</p>

                        </div>
                    </div>

                    <div class="form-group text-center m-t-20">
                        <div class="col-xs-12">
                            <button class="btn btn-info btn-lg btn-block text-uppercase waves-effect waves-light" type="submit">Regístrate</button>
                        </div>
                    </div>
                    <div class="form-group m-b-0">
                        <div class="col-sm-12 text-center">
                            <p>¿Ya tienes una cuenta?<a routerLink="/login" class="text-info m-l-5"><b>Iniciar sesión</b></a></p>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>
<!-- ============================================================== -->
<!-- End Wrapper -->
<!-- ============================================================== -->