<div class="row animated fadeIn fast">

    <div class="col-4">
        <div class="card">
            <div class="card-body">
                <h3>Usuarios</h3>

                <div class="alert alert-info animated fadeIn fast" *ngIf="usuarios.length === 0">
                    <p class="mb-0">
                        No hay usuarios con ese término
                    </p>
                </div>

                <div class="table-responsive" *ngIf="usuarios.length > 0">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Avatar</th>
                                <th>Nombre</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let usuario of usuarios" class="animated fadeIn fast">
                                <td>
                                    <img [src]="usuario.img | imagen:'usuarios'" class="w75" />
                                </td>
                                <td> <a [routerLink]="['/dashboard/usuarios']">
                                    {{ usuario.nombre }}
                                </a></td>
                            </tr>
                        </tbody>
                    </table>
                </div>


            </div>
        </div>
    </div>

    <div class="col-4">
        <div class="card">
            <div class="card-body">
                <h3>Hospitales</h3>

                <div class="alert alert-info animated fadeIn fast" *ngIf="hospitales.length === 0">
                    <p class="mb-0">
                        No hay hospitales con ese término
                    </p>
                </div>

                <div class="table-responsive" *ngIf="hospitales.length > 0">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Foto</th>
                                <th>Nombre</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let hospital of hospitales" class="animated fadeIn fast">
                                <td>
                                    <img [src]="hospital.img | imagen:'hospitales'" class="w75" />
                                </td>
                                <td> <a [routerLink]="['/dashboard/hospitales']">
                                        {{ hospital.nombre }}
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>


            </div>
        </div>
    </div>

    <div class="col-4">
        <div class="card">
            <div class="card-body">
                <h3>Médicos</h3>

                <div class="alert alert-info animated fadeIn fast" *ngIf="medicos.length === 0">
                    <p class="mb-0">
                        No hay médicos con ese término
                    </p>
                </div>

                <div class="table-responsive" *ngIf="medicos.length > 0">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Foto</th>
                                <th>Médico</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let medico of medicos" class="animated fadeIn fast">
                                <td>
                                    <img [src]="medico.img | imagen:'medicos'" class="w75" />
                                </td>
                                <td>
                                    <a [routerLink]="['/dashboard/medico', medico.mid ]">
                                        {{ medico.nombre }}
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>


            </div>
        </div>
    </div>

    <div class="col-4">
        <div class="card">
            <div class="card-body">
                <h3>Pacientes</h3>

                <div class="alert alert-info animated fadeIn fast" *ngIf="medicos.length === 0">
                    <p class="mb-0">
                        No hay pacientes con ese término
                    </p>
                </div>

                <div class="table-responsive" *ngIf="medicos.length > 0">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Pacientes</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let paciente of pacientes" class="animated fadeIn fast">

                                <td>
                                    <a [routerLink]="['/dashboard/paciente', paciente._id ]">
                                        {{ paciente.nombre }} {{ paciente.apellido1}} {{ paciente.apellido2 }}
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>


            </div>
        </div>
    </div>

    <div class="col-4">
        <div class="card">
            <div class="card-body">
                <h3>Examenes</h3>

                <div class="alert alert-info animated fadeIn fast" *ngIf="medicos.length === 0">
                    <p class="mb-0">
                        No hay examenes con ese término
                    </p>
                </div>

                <div class="table-responsive" *ngIf="medicos.length > 0">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Examenes</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let examen of examenes" class="animated fadeIn fast">

                                <td>
                                    <a [routerLink]="['/dashboard/examen-secretaria', examen._id ]">
                                        {{ examen.tipoExamen }}
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>


            </div>
        </div>
    </div>
</div>