<div class="container-fluid justify-content-center align-items-center vh-100">
    <!-- ============================================================== -->
    <!-- Start Page Content -->
    <!-- ============================================================== -->
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">Estado actual Medscan</h4>
                    <div class="row m-t-40">
                        <!-- Column -->
                        <div class="col-md-6 col-lg-2 col-xlg-2">
                            <div class="card">
                                <div class="box bg-secondary text-center">
                                    <h1 class="font-light text-white">{{ pacientes.length }}</h1>
                                    <h6 class="text-white">Total Pacientes</h6>
                                </div>
                            </div>
                        </div>
                        <!-- Column -->
                        <div class="col-md-6 col-lg-2 col-xlg-2">
                            <div class="card">
                                <div class="box bg-primary text-center">
                                    <h1 class="font-light text-white">{{ examenes.length }}</h1>
                                    <h6 class="text-white">Total Exámenes</h6>
                                </div>
                            </div>
                        </div>
                        <!-- Column -->
                        <div class="col-md-6 col-lg-2 col-xlg-2">
                            <div class="card">
                                <div class="box bg-success text-center">
                                    <h1 class="font-light text-white">{{ examenesFinalizados.length }}</h1>
                                    <h6 class="text-white">Exámenes Finalizados</h6>
                                </div>
                            </div>
                        </div>
                        <!-- Column -->
                        <div class="col-md-6 col-lg-2 col-xlg-2">
                            <div class="card">
                                <div class="box bg-info text-center">
                                    <h1 class="font-light text-white">{{ examenesRevisionTecnologo.length }}</h1>
                                    <h6 class="text-white">Exámenes en Revisión Tecnólogo</h6>
                                </div>
                            </div>
                        </div>
                        <!-- Column -->
                        <div class="col-md-6 col-lg-2 col-xlg-2">
                            <div class="card">
                                <div class="box bg-dark text-center">
                                    <h1 class="font-light text-white">{{ examenesRevisionMedico.length }}</h1>
                                    <h6 class="text-white">Exámenes en Revisión Médico</h6>
                                </div>
                            </div>
                        </div>
                        <!-- Column -->
                        <div class="col-md-6 col-lg-2 col-xlg-2">
                            <div class="card">
                                <div class="box bg-danger text-center">
                                    <h1 class="font-light text-white">0</h1>
                                    <h6 class="text-white">Exámenes Enviados</h6>
                                </div>
                            </div>
                        </div>
                        <!-- Tabla examenes -->

                        <div class="row animated fadeIn fast" *ngIf="cargando">
                            <div class="col-12">

                                <div class="alert alert-info text-center">
                                    <h4 class="alert-heading">Cargando</h4>
                                    <i class="fa fa-spin fa-refresh fa-2x"></i>
                                    <p class="mb-0">Por favor espere</p>
                                </div>

                            </div>
                        </div>


                        <div class="row" *ngIf="!cargando">
                            <div class="col-12">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="">

                                            <div class="row">
                                                <div class="col-8">
                                                    <h4 class="card-title">Exámenes para hoy ({{ examenesHoy.length }})</h4>
                                                    <h6 class="card-subtitle">
                                                        Exámenes registrados en Medscan
                                                    </h6>
                                                </div>
                                            </div>

                                            <div class="table-responsive">
                                                <table class="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th>Nombre paciente</th>
                                                            <th>Tipo de exámen</th>
                                                            <th>Hora de examen</th>
                                                            <th>Forma de pago</th>
                                                            <th>Médico</th>
                                                            <th>Tipo de exámen</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody *ngFor="let examen of examenesHoy">
                                                        <tr>
                                                            <td>
                                                                {{ examen.paciente.nombre }} {{ examen.paciente.apellido1 }} {{ examen.paciente.apellido2 }}
                                                            </td>
                                                            <td>
                                                                {{ examen.tipoExamen }}
                                                            </td>
                                                            <td>
                                                                {{ examen.horaExamen | date:'dd-MM-yyyy - HH:mm' }}
                                                            </td>
                                                            <td>
                                                                {{ examen.formaPago }}
                                                            </td>
                                                            <td>
                                                                {{ examen.usuario.nombre }}
                                                            </td>
                                                            <td>
                                                                {{ examen.tipoExamen }}
                                                            </td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                            <button class="btn btn-secondary">Anteriores</button> &nbsp;
                                            <button class="btn btn-secondary">Siguientes</button>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- ============================================================== -->
    <!-- End PAge Content -->
    <!-- ============================================================== -->
</div>