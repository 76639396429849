import { Component, OnInit, OnDestroy } from '@angular/core';
import { Hospital } from 'src/app/models/hospital.model';
import { HospitalService } from '../../../services/hospitales.service';
import { delay, filter } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { Subscription } from 'rxjs';
import { ModalImagenService } from '../../../services/modal-imagen.service';
import { BusquedasService } from '../../../services/busquedas.service';

@Component({
  selector: 'app-hospitales',
  templateUrl: './hospitales.component.html',
  styles: [
  ]
})
export class HospitalesComponent implements OnInit, OnDestroy{

  // public hospitalTemp: Hospital[] = [];
  // public totalHospital: number = 0;
  // public desde: number = 0;
  public p: number = 1;

  public hospitales: Hospital[] = [];
  public cargando: boolean = true;
  private imgSubs: Subscription;

  constructor( private hospitalesService : HospitalService,
               private modalImagenService: ModalImagenService,
               private busquedaService: BusquedasService )  { }

  ngOnDestroy(): void {
    this.imgSubs.unsubscribe();
  }

  ngOnInit(): void {
    this.cargarHospitales();

    this.imgSubs = this.modalImagenService.nuevaImagen
      .pipe(delay(100))
      .subscribe(img => this.cargarHospitales());

  }

  /* public cambiarPagina(valor: number) {
    this.desde += valor;

    if (this.desde < 0) {
      this.desde = 0;
    } else if (this.desde >= this.totalHospital) {
      this.desde -= valor;
    }
    this.cargarHospitales();
  }

  */


  public buscar ( termino: string){
    if(termino.length === 0){
      return this.cargarHospitales();
    }

    this.busquedaService.buscar('hospitales',termino)
      .subscribe(resp =>{
        this.hospitales = resp;
      });
  }

  public cargarHospitales(){
    this.cargando = true;
    this.hospitalesService.cargarHospitales()
      .subscribe( hospitales =>{
        this.cargando = false;
        this.hospitales = hospitales;
      })
   /* this.cargando = true;
    this.hospitalesService.cargarHospitales(this.desde)
      .subscribe(({ total, hospitales }) => {
        this.totalHospital = total;
        this.hospitales = hospitales;
        this.hospitalTemp = hospitales;
        this.cargando = false;
      });
    */
  }

  public guardarCambios( hospital : Hospital){

    this.hospitalesService.actualizarHospital(hospital._id, hospital.nombre)
      .subscribe(resp =>{
        Swal.fire('Actualizado', hospital.nombre, 'success')
      }, (err) => {
        console.log(err);
        Swal.fire('Error', err.error.errors.nombre.msg, 'error');
      });
  }

  public eliminarHospital( hospital : Hospital){



      Swal.fire({
        title: '¿Borrar hospital?',
        text: `Esta a punto de eliminar a ${hospital.nombre}!`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, borrarlo!'
      }).then((result) => {
        if(result.value){

        this.hospitalesService.borrarHospital(hospital._id)
          .subscribe(resp => {

            this.cargarHospitales();
            Swal.fire('Hospital borrado',
              `${hospital.nombre} fue eliminado correctamente`,
              'success'
            )
          });
        }
      })

    }

  //  this.hospitalesService.borrarHospital(hospital._id)
  //    .subscribe(resp =>{
  //      this.cargarHospitales();
  //       Swal.fire('Borrado', hospital.nombre, 'success')
  //    });
 // }

  public async abrirSweetAlert(){
    const { value = '' } = await Swal.fire<string>({
      title: 'Crear Hospital',
      text:'Ingrese el nombre del nuevo hospital',
      input: 'text',
      inputPlaceholder: 'Nombre del hospital ',
      showCancelButton: true,
    })
    if(value.trim().length > 2){
      this.hospitalesService.crearHospital(value)
        .subscribe((resp: any) =>{
          this.hospitales.push(resp.hospital)
        Swal.fire('Hospital ',`Hospital ${value} creado exitosamente`, 'success');
        })
    } else if ( (value.length < 3) && (value.length > 1)){
      Swal.fire('Hospital ','Ingrese un hospital no vacio y con minimo 3 caracteres', 'error');
    } else if ( value === ''){
      console.log('cerrar');
    }
  }

  public abrirModal(hospital: Hospital ){
    this.modalImagenService.abrirModal('hospitales', hospital._id, hospital.img)
  }

}
