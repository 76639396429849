<div class="row animated fadeIn fast">
    <div class="col-12">
        <div class="card">
            <div class="card-body">

                <input type="text" class="form-control" placeholder="Buscar paciente..." #txtTermino (keyup)="buscar( txtTermino.value )" />

            </div>
        </div>
    </div>
</div>


<div class="row animated fadeIn fast" *ngIf="cargando">
    <div class="col-12">

        <div class="alert alert-info text-center">
            <h4 class="alert-heading">Cargando</h4>
            <i class="fa fa-spin fa-refresh fa-2x"></i>
            <p class="mb-0">Por favor espere</p>
        </div>

    </div>
</div>


<div class="row" *ngIf="!cargando">
    <div class="col-12">
        <div class="card">
            <div class="card-body">


                <div class="row">
                    <div class="col-8">
                        <h4 class="card-title">Pacientes ({{ pacientes.length }})</h4>
                        <h6 class="card-subtitle">
                            Pacientes registrados en Medscan
                        </h6>
                    </div>

                    <div class="col">
                        <div class="text-right">
                            <a class="btn btn-primary" routerLink="/dashboard/paciente/nuevo">
                                <i class="fa fa-hospital-o"></i> Nuevo paciente
                            </a>
                        </div>
                    </div>
                </div>


                <div class="table-responsive">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th>Apellidos</th>
                                <th>Rut</th>
                                <th>Fecha nacimiento</th>
                                <th>Correo</th>
                                <th>Teléfono</th>
                                <th class="w100 text-nowrap">Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let paciente of pacientes | paginate: { itemsPerPage: 8, currentPage: p }">
                                <td>
                                    {{ paciente.nombre }}
                                </td>

                                <td>
                                    {{ paciente.apellido1 }} {{ paciente.apellido2}}
                                </td>

                                <td>
                                    {{ paciente.rut }}
                                </td>

                                <td>
                                    {{ paciente.fechaNac | date:'dd-MM-yyyy' }}
                                </td>

                                <td>
                                    {{ paciente.correo }}
                                </td>

                                <td>
                                    {{ paciente.telefono }}
                                </td>

                                <td class="text-nowrap text-center">
                                    <a [routerLink]="['/dashboard','paciente', paciente._id ]" data-toggle="tooltip" data-original-title="Editar">
                                        <i class="fa fa-pencil text-inverse m-r-10"></i>
                                    </a>

                                    <a class="cursor" data-toggle="tooltip" data-original-title="Borrar" (click)="borrarPaciente( paciente )">
                                        <i class="fa fa-close text-danger"></i>
                                    </a>
                                </td>
                            </tr>

                        </tbody>
                    </table>
                    <div>
                        <pagination-controls (pageChange)="p = $event" previousLabel="Anterior" nextLabel="Siguiente"></pagination-controls>
                    </div>
                </div>
                <!--
              <button class="btn btn-secondary">Anteriores</button>
              &nbsp;
              <button class="btn btn-secondary">Siguientes</button> -->
            </div>
        </div>
        <div class="col">
            <div class="text-right">
                <a class="btn btn-primary" routerLink="/dashboard/examen-secretaria/nuevo">
                    <i class="fa fa-hospital-o"></i> Crear exámen
                </a>
            </div>
        </div>
    </div>
</div>