import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UsuarioService } from '../services/usuario.service';

@Injectable({
  providedIn: 'root'
})
export class MedicoGuard implements CanActivate {

  constructor( private usuarioService : UsuarioService ,
               private router: Router 
             ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
     
      if (this.usuarioService.usuario.role === 'MEDIC_ROLE' || this.usuarioService.usuario.role === 'ADMIN_ROLE' ) {
        return true;
      } else {
        this.router.navigateByUrl('/dashboard');
        return false;
      }

  }
  
}
