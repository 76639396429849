<div class="row animated fadeIn fast">
    <div class="col-12">
        <div class="card">
            <div class="card-body">

                <input type="text" class="form-control" placeholder="Buscar hospital..." #txtTermino (keyup)="buscar(txtTermino.value)" />

            </div>
        </div>
    </div>
</div>

<div class="row animated fadeIn fast" *ngIf="cargando">
    <div class="col-12">

        <div class="alert alert-info text-center">
            <h4 class="alert-heading">Cargando</h4>
            <i class="fa fa-spin fa-refresh fa-2x"></i>
            <p class="mb-0">Por favor espere</p>
        </div>

    </div>
</div>


<div class="row" *ngIf="!cargando">

    <div class="col-12">
        <div class="card">
            <div class="card-body">

                <div class="row">
                    <div class="col-8">
                        <h4 class="card-title">Hospitales ({{ hospitales.length }})</h4>
                        <h6 class="card-subtitle">
                            Hospitales registrados en Medscan
                        </h6>
                    </div>

                    <div class="col">
                        <div class="text-right">
                            <button class="btn btn-primary" (click)="abrirSweetAlert()">
                              <i class="fa fa-hospital-o"></i>
                              Crear hospital
                          </button>
                        </div>
                    </div>

                </div>






                <div class="table-responsive">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th class="w100">Foto</th>
                                <th>Nombre</th>
                                <th class="w100 text-nowrap">Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let hospital of hospitales | paginate: { itemsPerPage: 4, currentPage: p }">
                                <td class="text-center">
                                    <img [src]="hospital.img | imagen:'hospitales'" [alt]="hospital.nombre" class="w100 cursor" (click)="abrirModal(hospital)">
                                </td>
                                <td>
                                    <input type="text" class="form-control" placeholder="Nombre del hospital" [(ngModel)]="hospital.nombre" />
                                </td>

                                <td class="text-nowrap text-center">
                                    <a (click)="guardarCambios(hospital)" data-toggle="tooltip" class="cursor" data-original-title="Guardar cambios">
                                        <i class="fa fa-pencil text-inverse m-r-10"></i>
                                    </a>

                                    <a (click)="eliminarHospital(hospital)" data-toggle="tooltip" data-original-title="Borrar" class="cursor">
                                        <i class="fa fa-close text-danger"></i>
                                    </a>
                                </td>
                            </tr>

                        </tbody>
                    </table>
                    <div>
                        <pagination-controls (pageChange)="p = $event" previousLabel="Anterior" nextLabel="Siguiente"></pagination-controls>
                    </div>
                </div>

                <!-- <button class="btn btn-secondary">Anteriores</button>
              &nbsp;
              <button class="btn btn-secondary">Siguientes</button> -->
                <!--  <div *ngIf="txtTermino.value.length === 0">
                <button (click)="cambiarPagina(-5)" class="btn btn-secondary">Anteriores</button> &nbsp;
                <button (click)="cambiarPagina(5)" class="btn btn-secondary">Siguientes</button>
            </div>
        -->
            </div>
        </div>
    </div>
</div>