<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<section id="wrapper">
    <div class="login-register" style="background-image:url(../assets/images/background/login-register-medscan.jpg);">
        <div class="login-box card">
            <div class="card-body border">
                <form class="form-horizontal form-material" autocomplete="off" [formGroup]="loginForm" (ngSubmit)="login()" id="loginform">

                    <a href="javascript:void(0)" class="text-center db"><img src="../assets/images/logo-medscan.jpg" alt="Home" /><br/></a>
                    <div class="form-group m-t-40">
                        <div class="col-xs-12">
                            <input class="form-control" type="email" placeholder="Correo" formControlName="email">
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="col-xs-12">
                            <input class="form-control" type="password" placeholder="Contraseña" formControlName="password">
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-md-12">
                            <div class="checkbox checkbox-primary pull-left p-t-0">
                                <input id="checkbox-signup" type="checkbox" class="filled-in chk-col-light-blue" formControlName="remember">
                                <label for="checkbox-signup"> Recuerdame </label>
                            </div>
                            <a href="javascript:void(0)" id="to-recover" class="text-dark pull-right"><i class="fa fa-lock m-r-5"></i> 
                                ¿Se te olvidó tu contraseña?</a> </div>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-12 m-t-10 mb-4 text-center">
                        <ngx-recaptcha2 #captchaElem [siteKey]="siteKey" formControlName="recaptcha">
                        </ngx-recaptcha2>
                    </div>
                    <div class="row">
                        <div class="col text-danger">
                            <p *ngIf="aceptaCaptcha()">* Debe realizar el captcha No soy un robot</p>
                        </div>
                    </div>
                    <div class="form-group text-center m-t-20">
                        <div class="col-xs-12">
                            <button class="btn btn-info btn-lg btn-block text-uppercase btn-rounded" type="submit">Iniciar sesión</button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 m-t-10 mb-4 text-center" id="google-btn">
                            <div id="my-signin2"></div>
                        </div>
                    </div>
                    <div class="form-group m-b-0">
                        <div class="col-sm-12 text-center">
                            ¿No tienes una cuenta? <a routerLink="/register" class="text-primary m-l-5"><b>Crear cuenta</b></a>
                        </div>
                    </div>
                </form>
                <form class="form-horizontal" id="recoverform" action="index.html">
                    <div class="form-group ">
                        <div class="col-xs-12">
                            <h3>Recuperar contraseña</h3>
                            <p class="text-muted">Ingrese su correo electrónico y se le enviarán las instrucciones.</p>
                        </div>
                    </div>
                    <div class="form-group ">
                        <div class="col-xs-12">
                            <input class="form-control" type="text" required="" placeholder="Email">
                        </div>
                    </div>
                    <div class="form-group text-center m-t-20">
                        <div class="col-xs-12">
                            <button class="btn btn-primary btn-lg btn-block text-uppercase waves-effect waves-light" type="submit">Reset</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>
<!-- ============================================================== -->
<!-- End Wrapper -->
<!-- ============================================================== -->