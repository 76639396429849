<div class="fondo-modal-imagen animated fadeIn fast" [class.oculto]="modalImagenService.ocultarModal">
    <div class="modal fade show" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel1" style="display: block; padding-right: 17px;">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title" id="exampleModalLabel1">Cargar imagen</h4>
                    <button type="button" class="close cursor" data-dismiss="modal" aria-label="Close" (click)="cerrarModal()"><span aria-hidden="true">×</span></button>
                </div>
                <div class="modal-body">
                    <div class="text-center">
                        <img *ngIf="!imgTemp" [src]="modalImagenService.img" class="img-avatar">
                        <img *ngIf="imgTemp" [src]="imgTemp" class="img-avatar">
                    </div>

                    <input type="file" (change)="cambiarImagen($event.target.files[0])">
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-default cursor" data-dismiss="modal" (click)="cerrarModal()">Cancelar</button>
                    <button type="button" class="btn btn-primary" (click)="subirImagen()">Actualizar imagen</button>
                </div>
            </div>
        </div>
    </div>
</div>