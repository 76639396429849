import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

import { AuthGuard } from '../guards/auth.guard';

import { PagesComponent } from './pages.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ProgressComponent } from './progress/progress.component';
import { Grafica1Component } from './grafica1/grafica1.component';
import { AccountSettingsComponent } from './account-settings/account-settings.component';
import { PromesasComponent } from './promesas/promesas.component';
import { RxjsComponent } from './rxjs/rxjs.component';
import { PerfilComponent } from './perfil/perfil.component';

// Mantenimientos
import { UsuariosComponent } from './mantenimientos/usuarios/usuarios.component';
import { HospitalesComponent } from './mantenimientos/hospitales/hospitales.component';
import { MedicosComponent } from './mantenimientos/medicos/medicos.component';
import { MedicoComponent } from './mantenimientos/medicos/medico.component';

// Pacientes
import { FormularioPacientesComponent } from './secretaria/formulario-pacientes/formulario-pacientes.component';
import { BusquedaComponent } from './busqueda/busqueda.component';


// Guards
import { AdminGuard } from '../guards/admin.guard';
import { MedicoGuard } from '../guards/medico.guard';
import { TecnologoGuard } from '../guards/tecnologo.guard';
import { SecretariaGuard } from '../guards/secretaria.guard';
import { MantendorGuard } from '../guards/mantendor.guard';

// Medicos


// Secretaria
import { ListaPacientesComponent } from './secretaria/lista-pacientes/lista-pacientes.component';
import { ExamenesSecretariaComponent } from './secretaria/examenes-enviados/examenes-secretaria.component';
import { ExamenSecretariaComponent } from './secretaria/examen-secretaria/examen-secretaria.component';
import { ExamenesMedicosComponent } from './medicos/examenes-medicos/examenes-medicos.component';
import { ExamenMedicosComponent } from './medicos/examen-medicos/examen-medicos.component';
import { ExamenesTecnologosComponent } from './tecnologos/examenes-tecnologos/examenes-tecnologos.component';
import { ExamenTecnologosComponent } from './tecnologos/examen-tecnologos/examen-tecnologos.component';
import { ExamenesFinalizadosComponent } from './secretaria/examenes-finalizados/examenes-finalizados.component';
import { ExamenFinalizadosComponent } from './secretaria/examen-finalizados/examen-finalizados.component';



const routes: Routes = [
    {
        path: 'dashboard',
        component: PagesComponent,
        canActivate: [ AuthGuard ],
        children: [
            { path: '', component: DashboardComponent, data: { titulo: 'Dashboard' } },
            { path: 'progress', component: ProgressComponent, data: { titulo: 'ProgressBar' }},
            { path: 'buscar/:termino', component: BusquedaComponent, data: { titulo: 'Busquedas' }},
            { path: 'grafica1', component: Grafica1Component, data: { titulo: 'Gráfica #1' }},
            { path: 'account-settings', component: AccountSettingsComponent, data: { titulo: 'Ajustes de cuenta' }},
            { path: 'promesas', component: PromesasComponent, data: { titulo: 'Promesas' }},
            { path: 'rxjs', component: RxjsComponent, data: { titulo: 'RxJs' }},
            { path: 'perfil', component: PerfilComponent, data: { titulo: 'Perfil de usuario' }},

            // Mantenimientos
           // { path: 'hospitales', component: HospitalesComponent, data: { titulo: 'Hospitales de aplicacion' }},
           // { path: 'medicos', component: MedicosComponent, data: { titulo: 'Médicos de aplicacion' }},
           // { path: 'medico/:id', component: MedicoComponent, data: { titulo: 'Médicos de aplicacion' }},

            // Médicos
           // { path: 'examenes-medicos', component: ExamenesMedicosComponent, data: { titulo: 'Exámenes' }},
           // { path: 'examen-medicos/:id', component: ExamenMedicosComponent, data: { titulo: 'Exámen' }},

            // Tecnólogos
            //{ path: 'examenes-tecnologos', component: ExamenesTecnologosComponent, data: { titulo: 'Exámenes' }},
            //{ path: 'examen-tecnologos/:id', component: ExamenTecnologosComponent, data: { titulo: 'Exámen' }},

            // Secretaria
           // { path: 'lista-pacientes', component: ListaPacientesComponent, data: { titulo: 'Listar pacientes' }},
           // { path: 'paciente/:id', component: FormularioPacientesComponent, data: { titulo: 'Formulario pacientes' }},
           // { path: 'examenes-seretaria', component: ExamenesSecretariaComponent, data: { titulo: 'Exámenes' }},
           // { path: 'examen-seretaria/:id', component: ExamenSecretariaComponent, data: { titulo: 'Exámen' }},

             // Rutas de Admin
             { path: 'usuarios', canActivate: [ AdminGuard ], component: UsuariosComponent, data: { titulo: 'Matenimiento de Usuarios' }},
            
             // Rutas de Medico / Mantenimiento
            { path: 'medicos', canActivate: [ MantendorGuard ] , component: MedicosComponent, data: { titulo: 'Médicos de aplicacion' }},
            { path: 'medico/:id', canActivate: [ MantendorGuard ] ,component: MedicoComponent, data: { titulo: 'Médicos de aplicacion' }},


             // Rutas de Medico / Medicos
             { path: 'examenes-medicos', canActivate: [ MedicoGuard ] ,component: ExamenesMedicosComponent, data: { titulo: 'Exámenes' }},
             { path: 'examen-medicos/:id', canActivate: [ MedicoGuard ] , component: ExamenMedicosComponent, data: { titulo: 'Exámen' }},
            
             // Rutas de Tecnologo / Tecnologos
             { path: 'examenes-tecnologos', canActivate:[ TecnologoGuard ] ,component: ExamenesTecnologosComponent, data: { titulo: 'Exámenes' }},
             { path: 'examen-tecnologos/:id', canActivate:[ TecnologoGuard ] , component: ExamenTecnologosComponent, data: { titulo: 'Exámen' }},
             
             // Rutas de Secretaria / Secretaria
             { path: 'lista-pacientes', canActivate: [ SecretariaGuard ] , component: ListaPacientesComponent, data: { titulo: 'Listar pacientes' }},
             { path: 'paciente/:id', canActivate: [ SecretariaGuard ] , component: FormularioPacientesComponent, data: { titulo: 'Formulario pacientes' }},
             { path: 'examenes-secretaria', canActivate: [ SecretariaGuard ] , component: ExamenesSecretariaComponent, data: { titulo: 'Exámenes' }},
             { path: 'examen-secretaria/:id', canActivate: [ SecretariaGuard ] , component: ExamenSecretariaComponent, data: { titulo: 'Exámen' }},
             { path: 'examenes-finalizados', canActivate: [ SecretariaGuard ] , component: ExamenesFinalizadosComponent, data: { titulo: 'Exámenes' }},
             { path: 'examen-finalizados/:id', canActivate: [ SecretariaGuard ] , component: ExamenFinalizadosComponent, data: { titulo: 'Exámen' }},
 
             // Rutas de hospitales
             { path: 'hospitales', canActivate: [ MantendorGuard ] , component: HospitalesComponent, data: { titulo: 'Hospitales de aplicacion' }},

        ]
    },
];

@NgModule({
    imports: [ RouterModule.forChild(routes) ],
    exports: [ RouterModule ]
})
export class PagesRoutingModule {}


