import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { environment } from '../../environments/environment.prod';
import { pipe } from 'rxjs';
import { map } from 'rxjs/operators';
import { Usuario } from '../models/usuario.model';
import { Hospital } from '../models/hospital.model';
import { Medico } from '../models/medico.model';
import { Examen } from '../models/examenes.model';
import { Paciente } from '../models/paciente.model';

const base_url = environment.base_url

@Injectable({
  providedIn: 'root'
})
export class BusquedasService {

  constructor(private http: HttpClient,
    private router: Router) { }

  public get token(): string {
    return localStorage.getItem('token') || '';
  }

  public get headers() {
    return {

      headers: {
        'token': this.token
      }
    }
  }

  private transformarUsuarios( resultados: any[]): Usuario[] {
    return resultados.map(
      user => new Usuario(user.nombre, user.email, '', user.img, user.google, user.role, user.uid)
    );
  }

  private transformarHospitales( resultados: any[]): Hospital[] {
    return resultados;
  }

  private transformarMedicos( resultados: any[]): Medico[] {
    return resultados;
  }

  private transformarPacientes( resultados: any[]): Paciente[] {
    return resultados;
  }

  private transformarExamenes( resultados: any[]): Examen[] {
    return resultados;
  }

  public busquedaGlobal( termino: string ) {

    const url = `${ base_url }/todo/${ termino }`;
    return this.http.get( url, this.headers );

  }

  public buscar(tipo: 'usuarios' | 'medicos' | 'hospitales' | 'pacientes' | 'examenes' | 'finalizados' | 'tecnologo' | 'medico', termino: string) {
    const url = `${base_url}/todo/coleccion/${tipo}/${termino}`;
    return this.http.get<any[]>(url, this.headers)
      .pipe(
        map((resp: any) => {
          switch (tipo) {
            case 'usuarios':
              return this.transformarUsuarios(resp.resultados);
            case 'hospitales':
              return this.transformarHospitales(resp.resultados);
            case 'medicos':
              return this.transformarMedicos(resp.resultados);
            case 'examenes':
              return this.transformarExamenes(resp.resultados);
            case 'pacientes':
              return this.transformarPacientes(resp.resultados);
            case 'finalizados':
              return this.transformarExamenes(resp.resultados);
            case 'tecnologo':
              return this.transformarExamenes(resp.resultados);
            case 'medico':
              return this.transformarExamenes(resp.resultados);
  

            default:
              return [];
          }
        })
      );
  }
}
