import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { BusquedasService } from 'src/app/services/busquedas.service';
import Swal from 'sweetalert2';
import { Examen } from '../../../models/examenes.model';
import { ExamenesService } from '../../../services/examenes.service';

@Component({
  selector: 'app-examenes-medicos',
  templateUrl: './examenes-medicos.component.html',
  styles: [
  ]
})
export class ExamenesMedicosComponent implements OnInit {

  public p: number = 1;

  public cargando: boolean = true;
  public examenes: Examen[] = [];
  private imgSubs: Subscription;

  constructor(private examenesService: ExamenesService,
    //private modalImagenService: ModalImagenService,
    private busquedasService: BusquedasService,
  ) { }

  ngOnDestroy(): void {
    //this.imgSubs.unsubscribe()
  }

  ngOnInit(): void {
    this.cargarExamenes();
  }

  public buscar( termino: string ) {

    if ( termino.length === 0 ) {
      return this.cargarExamenes();
    }

    this.busquedasService.buscar( 'medico', termino )
    .subscribe( resp => {
      this.examenes = resp;
    });
  }

  public cargarExamenes() {
    let emailUsuario = localStorage.getItem('emailUser');
    this.cargando = true;
    this.examenesService.cargarExamenes()
      .subscribe(examenes => {
        this.cargando = false;
        this.examenes = examenes.filter( estado => ( estado.paciente != null && estado.estado === 'Revision_Medico' && estado.usuario.email === emailUsuario ));
      });
  }

  public borrarExamen(examen: Examen) {

    Swal.fire({
      title: '¿Borrar exámen?',
      text: `Esta a punto de borrar a ${examen.tipoExamen}`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Si, borrarlo'
    }).then((result) => {
      if (result.value) {

        this.examenesService.borrarExamen(examen._id)
          .subscribe(resp => {
            this.cargarExamenes();
            Swal.fire(
              'Exámen borrado',
              `${examen.tipoExamen} fue eliminado correctamente`,
              'success'
            );
          });
      }
    })
  }

}
