<div class="row">
    <div class="col-md">
        <div class="card">
            <div class="card-body">

                <h4 class="card-title">Examen</h4>
                <h6 class="card-subtitle">Actualizar información</h6>

                <form class="form-horizontal p-t-20" [formGroup]="examenForm" (submit)="guardarExamen()">

                    <div class="form-group row">
                        <label for="exampleInputEmail3" class="col-sm-3 control-label">Paciente</label>
                        <div class="col-sm-9">
                            <div class="input-group">
                                <select class="form-control" formControlName="paciente">
                                  <option value="">Seleccione Paciente</option>
                                  <option *ngFor="let paciente of pacientes"
                                      [value]="paciente._id">
                                      {{ paciente.nombre }} {{ paciente.apellido1 }} {{ paciente.apellido2 }}
                                  </option>
                              </select>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label for="exampleInputuname3" class="col-sm-3 control-label">Tipo de Examen</label>
                        <div class="col-sm-9">
                            <div class="input-group">
                                <select class="form-control" formControlName="tipoExamen">
                                  <option value="">Seleccione examen</option>
                                  <option *ngFor="let examen of examenes"
                                      [value]="examen.nombre">
                                      {{ examen.nombre }}
                                  </option>
                              </select>
                            </div>
                            <!-- <div class="input-group">
                                <div class="input-group-addon"><i class="ti-user"></i></div>
                                <input type="text" formControlName="tipoExamen" class="form-control" id="exampleInputuname3" placeholder="Tipo de Examen">
                            </div> -->
                        </div>
                    </div>

                    <div class="form-group row">
                        <label for="exampleInputuname3" class="col-sm-3 control-label">Médico</label>
                        <div class="col-sm-9">
                            <div class="input-group">
                                <select class="form-control" formControlName="usuario">
                                  <option value="">Seleccione médico</option>
                                  <option *ngFor="let usuario of usuariosMedicos"
                                      [value]="usuario.uid">
                                      <ng-container *ngIf="usuario.role==='MEDIC_ROLE'">
                                        {{ usuario.nombre }}
                                      </ng-container>
                                  </option>
                              </select>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label for="exampleInputuname3" class="col-sm-3 control-label">Médico de referencia</label>
                        <div class="col-sm-9">
                            <div class="input-group">
                                <select class="form-control" formControlName="medico">
                                  <option value="">Seleccione Médico de referencia</option>
                                  <option *ngFor="let medico of medicos"
                                      [value]="medico.mid">
                                      {{ medico.nombre }} {{ medico.apellido1 }} {{ medico.apellido2 }}
                                  </option>
                              </select>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label for="exampleInputuname3" class="col-sm-3 control-label">Forma de Pago</label>
                        <div class="col-sm-9">
                            <div class="input-group">
                                <select class="form-control" formControlName="formaPago">
                                  <option value="">Seleccione forma de pago</option>
                                  <option *ngFor="let pago of pagos"
                                      [value]="pago.nombre">
                                      {{ pago.nombre }}
                                  </option>
                              </select>
                            </div>
                            <!-- <div class="input-group">
                                <div class="input-group-addon"><i class="ti-user"></i></div>
                                <input type="text" formControlName="formaPago" class="form-control" id="exampleInputuname3" placeholder="Forma de Pago del paciente">
                            </div> -->
                        </div>
                    </div>

                    <div class="form-group row">
                        <label for="example-datetime-local-input" class="col-2 col-form-label">Hora del examen</label>
                        <div class="col-10">
                            <input class="form-control" formControlName="horaExamen" type="datetime-local" id="example-datetime-local-input">
                        </div>
                    </div>

                    <div class="form-group row m-b-0">
                        <div class="offset-sm-3 col-sm-9">
                            <button type="submit" [disabled]="examenForm.invalid" class="btn btn-success waves-effect waves-light">
                              <i class="fa fa-save"></i>
                              Guardar
                          </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>