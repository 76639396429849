import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';

import { Paciente } from '../../../models/paciente.model';
import { Examen } from 'src/app/models/examenes.model';

import { FormularioPacientesService } from '../../../services/formulario-pacientes.service';
import { ExamenesService } from '../../../services/examenes.service';

import { delay } from 'rxjs/operators';
import * as dayjs from 'dayjs';

@Component({
  selector: 'app-examen-medicos',
  templateUrl: './examen-medicos.component.html',
  styles: [
  ]
})
export class ExamenMedicosComponent implements OnInit {

  //public examenForm: FormGroup;
  public pacientes: Paciente[] = [];

  public examenSeleccionado: Examen;
  public imagenExamenSeleccionado = null;
  public pacienteSeleccionado: Paciente;
  public pacienteSeleccionado2: Paciente;
  public  fechaNacPaciente2;
  public edad;

  public formSubmitted = false;

  public fecha = new Date();
  public examenForm: FormGroup = this.fb.group({
    paciente: ['', Validators.required],
    tipoExamen: ['', Validators.required],
    formaPago: ['', Validators.required],
    horaExamen: ['', Validators.required],
    medico: ['', Validators.required],
    usuario: ['', Validators.required],
    estado: ['', Validators.required],
    radioFarmaco: ['', Validators.required],
    observacionesTec: ['', Validators.required],
    observacionesTec2: ['', Validators.required],
    infoPaciente: ['', Validators.required],
    observacionesMed: ['', Validators.required],
    observacionesMed2: ['', Validators.required],
    //diagnostico: ['', [Validators.required, Validators.maxLength(25)]],
    diagnostico: ['', Validators.required],
    fechaIngreso: [''],
    horaFormateada: [''],
    edadPaciente: [''],
    img: [''],
  });

  constructor(private fb: FormBuilder,
    private pacientesService: FormularioPacientesService,
    private examenService: ExamenesService,
    private router: Router,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {

    this.activatedRoute.params
      .subscribe(({ id }) => this.cargarExamen(id));

    this.cargarPacientes();

    this.examenForm.get('paciente').valueChanges
      .subscribe(pacienteId => {
        this.pacienteSeleccionado = this.pacientes.find(paciente => paciente._id === pacienteId);
      })
  }

  public cargarExamen(id: string) {

    if (id === 'nuevo') {
      return;
    }

    this.examenService.obtenerExamenPorId(id)
      .pipe(
        delay(100)
      )
      .subscribe(examen => {

        if (!examen) {
          return this.router.navigateByUrl(`/dashboard/examenes-medicos`);
        }
        let convertirEdad = new Date(examen.paciente.fechaNac);
        let diferencia = Math.abs(Date.now() - convertirEdad.getTime());
        this.edad = Math.floor((diferencia / (1000 * 3600 * 24))/365);

        let { tipoExamen, horaExamen, formaPago, estado, radioFarmaco, observacionesTec, observacionesTec2, observacionesMed, diagnostico, paciente: { _id }, medico: {mid}, usuario: {uid}, fechaIngreso, horaFormateada, img, infoPaciente, edadPaciente } = examen;
        this.examenSeleccionado = examen;
        horaFormateada = dayjs(horaExamen).format('DD-MM-YYYY hh:mm');
        fechaIngreso = dayjs(this.fecha).format('MM/DD/YYYY');
        this.examenForm.setValue({ 
          tipoExamen,
          horaExamen, formaPago, 
          estado, 
          radioFarmaco, 
          observacionesTec,
          observacionesTec2,
          infoPaciente: '',
          observacionesMed: '',
          observacionesMed2: '',
          diagnostico: '',
          paciente: _id,
          medico: examen.medico._id,
          usuario: examen.usuario._id,
          fechaIngreso,
          horaFormateada,
          img: examen.img,
          edadPaciente: this.edad,
        });
        this.imagenExamenSeleccionado = examen.img;
      });

  }

  public cargarPacientes() {

    this.pacientesService.cargarPacientes()
      .subscribe((pacientes: Paciente[]) => {
        this.pacientes = pacientes;
      })

  }

  public guardarExamen() {

    if (this.examenSeleccionado) {
      // actualizar
      const data = {
        ...this.examenForm.value,
        _id: this.examenSeleccionado._id
      }
      console.log(data);
      this.examenService.actualizarExamen(data)
        .subscribe(resp => {
          Swal.fire('Actualizado', `Paciente actualizado correctamente`, 'success');
          this.router.navigateByUrl(`/dashboard/examenes-medicos`);
        }, (err) => {
          console.log(err);
          //Swal.fire('Error', err.error.errors.nombre.msg, 'error');
          Swal.fire('Error', err.error.errors.diagnostico.msg, 'error');
        }
        )


    } else {
      // crear

      this.examenService.crearExamen(this.examenForm.value)
        .subscribe((resp: any) => {
          Swal.fire('Creado', `Paciente creado correctamente`, 'success');
          this.router.navigateByUrl(`/dashboard/examen-tecnologos/${resp.examen._id}`)
        }, (err) => {
          console.log(err);
          //Swal.fire('Error', err.error.errors.diagnostico.msg, 'error');
          Swal.fire('Error', err.error.msg, 'error');
        },
        )
    }
  }

}