<div class="row animated fadeIn fast">
    <div class="col-12">
        <div class="card">
            <div class="card-body">

                <input type="text" class="form-control" placeholder="Buscar examen..." #txtTermino (keyup)="buscar( txtTermino.value )" />

            </div>
        </div>
    </div>
</div>


<div class="row animated fadeIn fast" *ngIf="cargando">
    <div class="col-12">

        <div class="alert alert-info text-center">
            <h4 class="alert-heading">Cargando</h4>
            <i class="fa fa-spin fa-refresh fa-2x"></i>
            <p class="mb-0">Por favor espere</p>
        </div>

    </div>
</div>


<div class="row" *ngIf="!cargando">
    <div class="col-12">
        <div class="card">
            <div class="card-body">


                <div class="row">
                    <div class="col-8">
                        <h4 class="card-title">Exámenes ({{ examenes.length }})</h4>
                        <h6 class="card-subtitle">
                            Exámenes registrados en Medscan
                        </h6>
                    </div>
                    <!--
                    <div class="col">
                        <div class="text-right">
                            <a class="btn btn-primary" routerLink="/dashboard/examen-medicos/nuevo">
                                <i class="fa fa-hospital-o"></i> Crear exámen
                            </a>
                        </div>
                    </div>
                    -->

                </div>



                <div class="table-responsive">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th class="w100">Examen</th>
                                <th>Nombre paciente</th>
                                <th>Tipo de exámen</th>
                                <th>Hora de examen</th>
                                <th>Forma de pago</th>
                                <th>Médico</th>
                                <th>Médico de referencia</th>
                                <th>Hospital</th>
                                <th>Observaciones Tecnólogo</th>

                                <th class="w100 text-nowrap">Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let examen of examenes| paginate: { itemsPerPage: 4, currentPage: p }">
                                <td class="text-center">
                                    <img [src]="examen.img | imagen:'examenes'" [alt]="examen._id" class="w100 cursor">
                                </td>
                                <td>
                                    {{ examen.paciente.nombre }} {{ examen.paciente.apellido1 }} {{ examen.paciente.apellido2 }}
                                </td>
                                <td>
                                    {{ examen.tipoExamen }}
                                </td>
                                <td>
                                    {{ examen.horaExamen | date:'dd-MM-yyyy - HH:mm' }}
                                </td>
                                <td>
                                    {{ examen.formaPago }}
                                </td>
                                <td>
                                    {{ examen.usuario.nombre }}
                                </td>
                                <td>
                                    {{ examen.medico.nombre}} {{ examen.medico.apellido1}}
                                </td>
                                <td>
                                    {{ examen.medico.hospital.nombre}}
                                </td>
                                <td>
                                    {{ examen.observacionesTec}}
                                </td>



                                <td class="text-nowrap text-center">
                                    <a [routerLink]="['/dashboard','examen-medicos', examen._id ]" data-toggle="tooltip" data-original-title="Editar">
                                        <i class="fa fa-pencil text-inverse m-r-10"></i>
                                    </a>

                                    <a class="cursor" data-toggle="tooltip" data-original-title="Borrar" (click)="borrarExamen( examen )">
                                        <i class="fa fa-close text-danger"></i>
                                    </a>
                                </td>
                            </tr>

                        </tbody>
                    </table>
                    <div>
                        <pagination-controls (pageChange)="p = $event" previousLabel="Anterior" nextLabel="Siguiente"></pagination-controls>
                    </div>
                </div>
                <!-- <button class="btn btn-secondary">Anteriores</button> &nbsp;
                <button class="btn btn-secondary">Siguientes</button> -->
            </div>
        </div>
    </div>
</div>