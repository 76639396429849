import { Component, OnInit, OnDestroy } from '@angular/core';
import Swal from 'sweetalert2';
import { Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';

import { Usuario } from 'src/app/models/usuario.model';

import { BusquedasService } from '../../../services/busquedas.service';
import { UsuarioService } from '../../../services/usuario.service';
import { ModalImagenService } from '../../../services/modal-imagen.service';

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styles: [
  ]
})
export class UsuariosComponent implements OnInit, OnDestroy {

  //public usuarios: Usuario[] = [];
  //public usuariosTemp: Usuario[] = [];
  //public totalUsuarios: number = 0;
  public p: number = 1;

  public usuarios: Usuario[] = [];

  public imgSubs: Subscription;
  public desde: number = 0;
  public cargando: boolean = true;

  constructor(private usuarioService: UsuarioService,
    private busquedasService: BusquedasService,
    private modalImagenServices: ModalImagenService) { }

  ngOnInit(): void {
    this.cargarUsuarios();
    this.imgSubs = this.modalImagenServices.nuevaImagen
      .pipe(delay(100))
      .subscribe(img => this.cargarUsuarios());
  }

  ngOnDestroy(): void {
    this.imgSubs.unsubscribe();
  }

  public cargarUsuarios() {
  /*  this.cargando = true;
    this.usuarioService.cargarUsuarios(this.desde)
      .subscribe(({ total, usuarios }) => {
        this.totalUsuarios = total;
        this.usuarios = usuarios;
        this.usuariosTemp = usuarios;
        this.cargando = false;
      });
  */
        this.cargando = true;
        this.usuarioService.cargarUsuarios()
          .subscribe( usuarios =>{
            this.cargando = false;
            this.usuarios = usuarios;
          })
  }

  /*public cambiarPagina(valor: number) {
    this.desde += valor;

    if (this.desde < 0) {
      this.desde = 0;
    } else if (this.desde >= this.totalUsuarios) {
      this.desde -= valor;
    }
    this.cargarUsuarios();
  }
  */

  public buscar(termino: string) {
    if (termino.length === 0) {
     // return this.usuarios = this.usuariosTemp;
     return this.cargarUsuarios();
    } else {

    }
    this.busquedasService.buscar('usuarios', termino)
      .subscribe((resp: Usuario[]) => {
        this.usuarios = resp;
      });
  }

  public eliminarUsuario(usuario: Usuario) {
    if (usuario.uid === this.usuarioService.uid) {
      return Swal.fire('Error', 'No puede borrarse a si mismo', 'error');
    }
    Swal.fire({
      title: 'Borrar usuario?',
      text: `Esta a punto de eliminar a ${usuario.nombre}!`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, borrarlo!'
    }).then((result) => {
      this.usuarioService.eliminarUsuario(usuario)
        .subscribe(resp => {
          this.cargarUsuarios();
          Swal.fire('Usuario borrado',
            `${usuario.nombre} fue eliminado correctamente`,
            'success'
          )
        });
    })
  }

  public cambiarRole(usuario: Usuario) {
    this.usuarioService.guardarUsuario(usuario)
      .subscribe(resp => {
        console.log(resp);
      });
  }

  public abrirModal(usuario: Usuario) {
    this.modalImagenServices.abrirModal('usuarios', usuario.uid, usuario.img);
  }
}
