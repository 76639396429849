import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Modulos
import { SharedModule } from '../shared/shared.module';
import { ComponentsModule } from '../components/components.module';


import { DashboardComponent } from './dashboard/dashboard.component';
import { ProgressComponent } from './progress/progress.component';
import { Grafica1Component } from './grafica1/grafica1.component';
import { PagesComponent } from './pages.component';
import { AccountSettingsComponent } from './account-settings/account-settings.component';
import { PromesasComponent } from './promesas/promesas.component';
import { RxjsComponent } from './rxjs/rxjs.component';
import { PerfilComponent } from './perfil/perfil.component';
import { UsuariosComponent } from './mantenimientos/usuarios/usuarios.component';
import { HospitalesComponent } from './mantenimientos/hospitales/hospitales.component';
import { MedicosComponent } from './mantenimientos/medicos/medicos.component';
import { PipesModule } from '../pipes/pipes.module';
import { MedicoComponent } from './mantenimientos/medicos/medico.component';
import { FormularioPacientesComponent } from './secretaria/formulario-pacientes/formulario-pacientes.component';
import { ListaPacientesComponent } from './secretaria/lista-pacientes/lista-pacientes.component';
import { BusquedaComponent } from './busqueda/busqueda.component';
import { ExamenesSecretariaComponent } from './secretaria/examenes-enviados/examenes-secretaria.component';
import { ExamenSecretariaComponent } from './secretaria/examen-secretaria/examen-secretaria.component';
import { ExamenesMedicosComponent } from './medicos/examenes-medicos/examenes-medicos.component';
import { ExamenesTecnologosComponent } from './tecnologos/examenes-tecnologos/examenes-tecnologos.component';
import { ExamenTecnologosComponent } from './tecnologos/examen-tecnologos/examen-tecnologos.component';
import { ExamenMedicosComponent } from './medicos/examen-medicos/examen-medicos.component';
import { ExamenesFinalizadosComponent } from './secretaria/examenes-finalizados/examenes-finalizados.component';
import { ExamenFinalizadosComponent } from './secretaria/examen-finalizados/examen-finalizados.component';
import { NgxPaginationModule } from 'ngx-pagination';



@NgModule({
  declarations: [
    DashboardComponent,
    ProgressComponent,
    Grafica1Component,
    PagesComponent,
    AccountSettingsComponent,
    PromesasComponent,
    RxjsComponent,
    PerfilComponent,
    UsuariosComponent,
    HospitalesComponent,
    MedicosComponent,
    MedicoComponent,
    FormularioPacientesComponent,
    ListaPacientesComponent,
    BusquedaComponent,
    ExamenesSecretariaComponent,
    ExamenSecretariaComponent,
    ExamenesMedicosComponent,
    ExamenesTecnologosComponent,
    ExamenTecnologosComponent,
    ExamenMedicosComponent,
    ExamenesFinalizadosComponent,
    ExamenFinalizadosComponent,
  ],
  exports: [
    DashboardComponent,
    ProgressComponent,
    Grafica1Component,
    PagesComponent,
    AccountSettingsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    RouterModule,
    ComponentsModule,
    PipesModule,
    NgxPaginationModule
  ]
})
export class PagesModule { }
