import { Component, OnInit } from '@angular/core';
import { Examen } from 'src/app/models/examenes.model';
import { Paciente } from 'src/app/models/paciente.model';
import { ExamenesService } from 'src/app/services/examenes.service';
import { FormularioPacientesService } from 'src/app/services/formulario-pacientes.service';
import * as dayjs from 'dayjs';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styles: [
  ]
})
export class DashboardComponent implements OnInit {
  public cargando: boolean = true;

  public pacientes: Paciente[] = [];
  public examenes: Examen[] = [];
  public examenesFinalizados: Examen[] = [];
  public examenesRevisionTecnologo: Examen[] = [];
  public examenesRevisionMedico: Examen[] = [];
  public examenesHoy: Examen[] = [];

  constructor(
    private pacienteService: FormularioPacientesService,
    private examenesService: ExamenesService,
  ) { }

  ngOnInit(): void {
    this.cargarPacientes();
    this.cargarExamenes();
  }

  public cargarPacientes() {
    this.pacienteService.cargarPacientes()
      .subscribe( pacientes => {
        this.pacientes = pacientes;
      });
  }

  public cargarExamenes() {
    let date: Date = new Date();
    this.cargando = true;
    this.examenesService.cargarExamenes()
      .subscribe(examenes => {
        this.cargando = false;
        this.examenes = examenes.filter( estado => estado.paciente != null);
        this.examenesFinalizados = examenes.filter(estado => (estado.estado === 'Finalizado' && estado.paciente != null));
        this.examenesRevisionTecnologo = examenes.filter(estado => (estado.estado === 'Revision_Tecnologo' && estado.paciente != null));
        this.examenesRevisionMedico = examenes.filter(estado => (estado.estado === 'Revision_Medico' && estado.paciente != null));
        examenes.filter(fecha => {
          let fechaHoy = dayjs(date).format('DD/MM/YYYY');
          let fechaExamen = dayjs(fecha.horaExamen).format('DD/MM/YYYY');
          let horaHoy = dayjs(date).format('HH:mm');
          let horaExamen = dayjs(fecha.horaExamen).format('HH:mm');
          if ((fechaExamen === fechaHoy) && (horaExamen >= horaHoy)) {
            this.examenesHoy.push(fecha);
          }
        })
      });
  }
}
