import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';
import { BusquedasService } from 'src/app/services/busquedas.service';
import { ModalImagenService } from 'src/app/services/modal-imagen.service';
import Swal from 'sweetalert2';
import { Examen } from '../../../models/examenes.model';
import { ExamenesService } from '../../../services/examenes.service';
@Component({
  selector: 'app-examenes-tecnologos',
  templateUrl: './examenes-tecnologos.component.html',
  styles: [
  ]
})
export class ExamenesTecnologosComponent implements OnInit {

  public p: number = 1;

  public cargando: boolean = true;
  public examenes: Examen[] = [];
  private imgSubs: Subscription;

  constructor(private examenesService: ExamenesService,
    private modalImagenService: ModalImagenService,
    private busquedasService: BusquedasService,
    ) { }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {
    this.cargarExamenes();
  }

  public abrirModal(examen: Examen) {

    this.modalImagenService.abrirModal( 'examenes', examen._id, examen.img );
  }

  public buscar( termino: string ) {

    if ( termino.length === 0 ) {
      return this.cargarExamenes();
    }

    this.busquedasService.buscar( 'tecnologo', termino )
    .subscribe( resp => {
      this.examenes = resp;
    });
  }

  public cargarExamenes() {
    let emailUsuario = localStorage.getItem('emailUser');
    this.cargando = true;
    this.examenesService.cargarExamenes()
      .subscribe(examenes => {
        this.cargando = false;
        this.examenes = examenes.filter( estado => ( estado.paciente != null && estado.estado === 'Revision_Tecnologo' ));
      });
  }

  public borrarExamen(examen: Examen) {

    Swal.fire({
      title: '¿Borrar exámen?',
      text: `Esta a punto de borrar a ${examen.tipoExamen}`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Si, borrarlo'
    }).then((result) => {
      if (result.value) {

        this.examenesService.borrarExamen(examen._id)
          .subscribe(resp => {

            this.cargarExamenes();
            Swal.fire(
              'Exámen borrado',
              `${examen.tipoExamen} fue eliminado correctamente`,
              'success'
            );
          });
      }
    })
  }

}
