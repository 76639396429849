<div class="row animated fadeIn fast">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <input type="text" class="form-control" placeholder="Buscar usuario..." (keyup)="buscar(txtTermino.value)" #txtTermino>
            </div>
        </div>
    </div>
</div>
<div class="row animated fadeIn fast" *ngIf="cargando">
    <div class="col-12">
        <div class="alert alert-info text-center">
            <h4 class="alert-heading">Cargando</h4>
            <i class="fa fa-spin fa-refresh fa-2x"></i>
            <p class="mb-0">Por favor espere</p>
        </div>
    </div>
</div>
<div class="row animated fadeIn fast" *ngIf="!cargando">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Total de usuarios ({{ usuarios.length }})</h4>
                <h6 class="card-subtitle">Usuarios registrados en Medscan</h6>
                <div class="table-responsive m-t-40">
                    <table id="example23" class="display nowrap table table-hover table-striped table-bordered" cellspacing="0" width="100%">
                        <thead>
                            <tr>
                                <!-- <th class="w100">Avatar</th> -->
                                <th>Correo</th>
                                <th>Nombre</th>
                                <th class="w160">Role</th>
                                <th class="w100">Auth</th>
                                <th class="text-nowrap w100">Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let usuario of usuarios | paginate: {
                                itemsPerPage: 5, 
                                currentPage: p }">
                                <!-- <td class="text-center">
                                    <img [src]="usuario.img" alt="Avatar de usuario" class="avatar cursor" (click)="abrirModal(usuario)">
                                </td> -->
                                <td>{{ usuario.email }}</td>
                                <td>{{ usuario.nombre }}</td>
                                <td>
                                    <select class="form-control" [(ngModel)]="usuario.role" (change)="cambiarRole(usuario)">
                                        <option value="ADMIN_ROLE">Admin</option>
                                        <option value="USER_ROLE">Paciente</option>
                                        <option value="MEDIC_ROLE">Médico</option>
                                        <option value="TMS_ROLE">Tecnólogo</option>
                                        <option value="SECRET_ROLE">Secretaria</option>
                                    </select>
                                </td>
                                <td>
                                    <span *ngIf="usuario.google" class="label label-danger">Google</span>
                                    <span *ngIf="!usuario.google" class="label label-info">Email</span>
                                </td>
                                <td class="text-nowrap text-center">
                                    <a class="cursor" data-toggle="tooltip" data-original-title="Borrar" (click)="eliminarUsuario( usuario)"> <i class="fa fa-close text-danger"></i> </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div>
                        <pagination-controls (pageChange)="p = $event" previousLabel="Anterior" nextLabel="Siguiente"></pagination-controls>
                    </div>
                </div>
                <!-- <div *ngIf="txtTermino.value.length === 0">
                    <button (click)="cambiarPagina(-5)" class="btn btn-secondary">Anteriores</button> &nbsp;
                    <button (click)="cambiarPagina(5)" class="btn btn-secondary">Siguientes</button>
                </div>
                -->
            </div>
        </div>
    </div>
</div>