import { Injectable, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable, of } from 'rxjs';
import { tap, map, catchError } from 'rxjs/operators';

import { environment } from '../../environments/environment.prod';

import { FormularioPacientes } from '../interfaces/formulario-pacientes.interface';
import { CargarPaciente } from '../interfaces/cargar-pacientes.interface';

import { Paciente } from '../models/paciente.model';

const base_url = environment.base_url;
declare const gapi: any;

@Injectable({
  providedIn: 'root'
})


export class FormularioPacientesService {

  constructor(private http: HttpClient,
    private router: Router,
    private ngZone: NgZone
    ) { }


    public get token(): string {
      return localStorage.getItem('token') || '';
    }

    public get headers() {
      return {
  
        headers: {
          'token': this.token
        }
      }
    }

    public guardarLocalStorage( token: string, menu: any ) {

      localStorage.setItem('token', token );
      localStorage.setItem('menu', JSON.stringify(menu) );
  
    }

    public cargarPacientes() {
      const url = `${base_url}/pacientes`;
      return this.http.get(url, this.headers)
        .pipe(
          map((resp: {ok: boolean, pacientes: Paciente[] }) => resp.pacientes)
        )
    }

    public obtenerPacientePorId( id: string ) {

      return this.http.get( `${ base_url }/pacientes/${ id }`, this.headers )
                .pipe(
                  map( (resp: {ok: boolean, paciente: Paciente }) => resp.paciente )
                );
    }

    public crearPaciente(paciente: FormularioPacientes) {
      return this.http.post(`${base_url}/pacientes`, paciente, this.headers);
    }

    public actualizarPaciente( paciente: Paciente) {
      return this.http.put( `${base_url}/pacientes/${ paciente._id }`, paciente, this.headers );
    }

    public borrarPaciente( _id: string ) {
      return this.http.delete( `${ base_url }/pacientes/${ _id }`, this.headers );
    }
}



