import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { delay, filter } from 'rxjs/operators';
import { BusquedasService } from '../../../services/busquedas.service';
import Swal from 'sweetalert2';
import { Examen } from '../../../models/examenes.model';
import { ExamenesService } from '../../../services/examenes.service';
import { FileUploadService } from 'src/app/services/file-upload.service';

@Component({
  selector: 'app-examenes-finalizados',
  templateUrl: './examenes-finalizados.component.html',
  styleUrls: []
})
export class ExamenesFinalizadosComponent implements OnInit {

  public p: number = 1;

  public cargando: boolean = true;
  public examenes: Examen[] = [];
  public examen: Examen;
  private imgSubs: Subscription;

  constructor(private examenesService: ExamenesService,

    //private modalImagenService: ModalImagenService,
    private busquedasService: BusquedasService,
  ) { }

  ngOnDestroy(): void {
    //this.imgSubs.unsubscribe()
  }

  ngOnInit(): void {
    this.cargarExamenes();
  }

  public buscar(termino: string) {

    if (termino.length === 0) {
      return this.cargarExamenes();
    }

    this.busquedasService.buscar('finalizados', termino)
      .subscribe(resp => {
        this.examenes = resp;
      });
  }

  public cargarExamenes() {
    this.cargando = true;
    this.examenesService.cargarExamenes()
      .subscribe(examenes => {
        this.cargando = false;
        this.examenes = examenes.filter(nulo => (nulo.paciente != null && nulo.estado === 'Finalizado'));
      });
  }

  public borrarExamen(examen: Examen) {

    Swal.fire({
      title: '¿Borrar médico?',
      text: `Esta a punto de borrar a ${examen.tipoExamen}`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Si, borrarlo'
    }).then((result) => {
      if (result.value) {

        this.examenesService.borrarExamen(examen._id)
          .subscribe(resp => {

            this.cargarExamenes();
            Swal.fire(
              'Médico borrado',
              `${examen.tipoExamen} fue eliminado correctamente`,
              'success'
            );
          });
      }
    })
  }

}
