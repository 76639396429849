<div class="row">
    <div class="col-md">
        <div class="card">
            <div class="card-body">

                <h4 class="card-title">Médico</h4>
                <h6 class="card-subtitle">Actualizar información</h6>

                <form class="form-horizontal p-t-20" [formGroup]="examenForm" (submit)="guardarExamen()">

                    <div class="form-group row">
                        <label for="exampleInputEmail3" class="col-sm-3 control-label">Paciente</label>
                        <div class="col-sm-9">
                            <div class="input-group">
                                <select class="form-control" formControlName="paciente" disabled>
                                  <option value="">Seleccione Paciente</option>
                                  <option *ngFor="let paciente of pacientes"
                                      [value]="paciente._id">
                                      {{ paciente.nombre }} {{ paciente.apellido1 }} {{ paciente.apellido2 }}
                                  </option>
                              </select>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label for="exampleInputuname3" class="col-sm-3 control-label">Tipo de Examen</label>
                        <div class="col-sm-9">
                            <div class="input-group">
                                <div class="input-group-addon"><i class="ti-user"></i></div>
                                <input type="text" formControlName="tipoExamen" class="form-control" id="exampleInputuname3" placeholder="Tipo de Examen" disabled>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label for="exampleInputuname3" class="col-sm-3 control-label">Forma de Pago</label>
                        <div class="col-sm-9">
                            <div class="input-group">
                                <div class="input-group-addon"><i class="ti-user"></i></div>
                                <input type="text" formControlName="formaPago" class="form-control" id="exampleInputuname3" placeholder="Forma de Pago del médico" disabled>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label for="exampleInputuname3" class="col-sm-3 control-label">Hora exámen</label>
                        <div class="col-sm-9">
                            <div class="input-group">
                                <div class="input-group-addon"><i class="ti-user"></i></div>
                                <input type="text" formControlName="horaFormateada" class="form-control" id="exampleInputuname3" placeholder="Forma de Pago del médico" disabled>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label for="exampleInputuname3" class="col-sm-3 control-label">Estado</label>
                        <div class="col-sm-9">
                            <select class="form-control" formControlName="estado" placeholder="Seleccione un Estado">
                                <option value="Revision_Tecnologo">Revisión Tecnólogo</option>
                                <option value="Finalizado">Finalizado</option>
                            </select>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label for="exampleInputuname3" class="col-sm-3 control-label">Radiofármaco</label>
                        <div class="col-sm-9">
                            <div class="input-group">
                                <div class="input-group-addon"><i class="ti-user"></i></div>
                                <input type="text" formControlName="radioFarmaco" class="form-control" id="exampleInputuname3" placeholder="Radiofármaco" disabled>
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label>Observaciones Tecnólogo</label>
                        <textarea class="form-control" formControlName="observacionesTec" rows="5" disabled></textarea>
                    </div>

                    <div class="form-group">
                        <label>Técnica / Proyección / Descripción estudio</label>
                        <textarea class="form-control" formControlName="observacionesTec2" rows="5" disabled></textarea>
                    </div>

                    <div class="form-group">
                        <label>Información Clínica</label>
                        <textarea class="form-control" formControlName="infoPaciente" rows="5"></textarea>
                    </div>

                    <div class="form-group">
                        <label>Hallazgos</label>
                        <textarea class="form-control" formControlName="observacionesMed" rows="5"></textarea>
                    </div>

                    <div class="form-group">
                        <label>Impresión Diagnóstica</label>
                        <textarea class="form-control" formControlName="observacionesMed2" rows="5"></textarea>
                    </div>

                    <div class="form-group">
                        <label>Diagnostico</label>
                        <textarea class="form-control" formControlName="diagnostico" rows="5"></textarea>
                    </div>

                    <div class="form-group center-button ">
                        <img [src]="imagenExamenSeleccionado | imagen:'examenes'" class="center-button w500">
                    </div>

                    <div class="form-group row m-b-0">
                        <div class="offset-sm-3 col-sm-9">
                            <button type="submit" [disabled]="examenForm.invalid" class="btn btn-success waves-effect waves-light">
                              <i class="fa fa-save"></i>
                              Enviar
                          </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>