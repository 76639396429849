import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';

import { Paciente } from '../../../models/paciente.model';
import { Examen } from '../../../models/examenes.model';
import { Usuario } from '../../../models/usuario.model';
import { Medico } from '../../../models/medico.model';

import { FormularioPacientesService } from '../../../services/formulario-pacientes.service';
import { ExamenesService } from '../../../services/examenes.service';
import { MedicoService } from '../../../services/medicos.service';

import { delay, timestamp } from 'rxjs/operators';
import * as dayjs from 'dayjs';
import { UsuarioService } from 'src/app/services/usuario.service';
@Component({
  selector: 'app-examen-tecnologos',
  templateUrl: './examen-tecnologos.component.html',
  styles: [
  ]
})
export class ExamenTecnologosComponent implements OnInit {

  //public examenForm: FormGroup;
  public pacientes: Paciente[] = [];
  public medicos: Medico[] = [];
  public examenSeleccionado: Examen;
  public pacienteSeleccionado2: Paciente;
  public imagenExamenSeleccionado = null;
  public pacienteSeleccionado: Paciente;
  public usuarios: Usuario[] = [];
  public usuariosTemp: Usuario[] = [];
  public totalUsuarios: number = 0;

  public fecha = new Date();
  public desde: number = 0;
  public fechaNacPaciente2;
  public edad;

  // Arreglo
  public radiofarmacos = [
    {id: 1,nombre: 'MDP'},
    {id: 2, nombre: 'MAG3'},
    {id: 3, nombre: 'MIBI'},
    {id: 4, nombre: 'DMSA'},
    {id: 5, nombre: 'DTPA'},
    {id: 6, nombre: 'ECD'},
    {id: 7, nombre: 'TRODAT'},
    {id: 8, nombre: 'INFECTON'},
    {id: 9, nombre: 'NANOCOLOIDES'},
    {id: 10, nombre: 'MAA'},
    {id: 11, nombre: 'OCTREOCTIDE'},
    {id: 12, nombre: 'FDG'},
    {id: 13, nombre: '99mTC'},
    {id: 14, nombre: 'PSMA'},
    {id: 15, nombre: 'SULFURO COLOIDES'},
    {id: 16, nombre: 'Ninguno'},
  ];

  public examenForm: FormGroup = this.fb.group({
    paciente: ['', Validators.required],
    tipoExamen: ['', Validators.required],
    formaPago: ['', Validators.required],
    horaExamen: ['', Validators.required],
    medico: ['', Validators.required],
    usuario: ['', Validators.required],
    estado: ['', Validators.required],
    radioFarmaco: ['', Validators.required],
    observacionesTec: ['', Validators.required],
    observacionesTec2: ['', Validators.required],
    fechaIngreso: ['', Validators.required],
    horaFormateada: ['', Validators.required],
    emailPaciente: ['', Validators.required],
    nombrePaciente: ['', Validators.required],
    apellido1Paciente: ['', Validators.required],
    apellido2Paciente: ['', Validators.required],
    rutPaciente: ['', Validators.required],
    medicoNombre: ['', Validators.required],
    usuarioNombre: ['', Validators.required],
    fechaNacPaciente: ['', Validators.required],
    img: [''],
  });

  constructor(private fb: FormBuilder,
    private usuarioService: UsuarioService,
    private medicoService: MedicoService,
    private pacientesService: FormularioPacientesService,
    private examenService: ExamenesService,
    private router: Router,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {

    this.cargarUsuarios();
    this.cargarMedicos();
    this.cargarPacientes();
    this.activatedRoute.params
      .subscribe(({ id }) => this.cargarExamen(id));

    this.examenForm.get('paciente').valueChanges
      .subscribe(pacienteId => {
        this.pacienteSeleccionado = this.pacientes.find(paciente => paciente._id === pacienteId);
      })
  }

  public calcularEdad(edad) {
    if(this.fechaNacPaciente2){
      let convertirEdad = new Date(this.fechaNacPaciente2);
      let diferencia = Math.abs(Date.now() - convertirEdad.getTime());
      edad = Math.floor((diferencia / (1000 * 3600 * 24))/365);
      return edad;
    }
  }

  public obtenerEdad() {
    this.examenForm.get('paciente').valueChanges
    .subscribe(pacienteId => {
      this.pacienteSeleccionado2 = this.pacientes.find(paciente =>{ 
        paciente._id === pacienteId
        if (paciente._id === pacienteId) {
          this.fechaNacPaciente2 = paciente.fechaNac;
          this.edad = this.calcularEdad(this.fechaNacPaciente2);
        }
      });
    })
  }

  public cargarExamen(id: string) {

    if (id === 'nuevo') {
      return;
    }

    this.examenService.obtenerExamenPorId(id)
      .pipe(
        delay(100)
      )
      .subscribe(examen => {

        if (!examen) {
          return this.router.navigateByUrl(`/dashboard/examenes-tecnologos`);
        }

        let { tipoExamen, horaExamen, formaPago, estado, paciente: { _id }, radioFarmaco, observacionesTec, observacionesTec2, medico: { mid }, usuario: {uid}, fechaIngreso, horaFormateada, img,
              emailPaciente, nombrePaciente, apellido1Paciente, apellido2Paciente, rutPaciente, medicoNombre, usuarioNombre, fechaNacPaciente } = examen;
        this.examenSeleccionado = examen;
        horaFormateada = dayjs(horaExamen).format('DD-MM-YYYY hh:mm');
        emailPaciente = examen.paciente.correo;
        nombrePaciente = examen.paciente.nombre;
        apellido1Paciente = examen.paciente.apellido1;
        apellido2Paciente = examen.paciente.apellido2;
        rutPaciente = examen.paciente.rut;
        medicoNombre = `${examen.medico.nombre} ${examen.medico.apellido1}`;
        this.examenForm.setValue({ 
          tipoExamen,
          horaExamen,
          formaPago,
          estado: 'Revision_Medico',
          paciente: _id,
          radioFarmaco: '',
          observacionesTec: '',
          observacionesTec2: '',
          medico: examen.medico._id,
          usuario: examen.usuario._id,
          fechaIngreso: '02-02-2021',
          horaFormateada,
          img: examen.img,
          emailPaciente,
          nombrePaciente,
          apellido1Paciente,
          apellido2Paciente,
          rutPaciente,
          medicoNombre,
          usuarioNombre: examen.usuario.nombre,
          fechaNacPaciente: examen.paciente.fechaNac,
        });
        this.imagenExamenSeleccionado = examen.img;
      });

  }

  public cargarPacientes() {

    this.pacientesService.cargarPacientes()
      .subscribe((pacientes: Paciente[]) => {
        this.pacientes = pacientes;
      })

  }

  public guardarExamen() {

    if (this.examenSeleccionado) {
      // actualizar
      const data = {
        ...this.examenForm.value,
        _id: this.examenSeleccionado._id
      }
      console.log(data);
      this.examenService.actualizarExamen(data)
        .subscribe(resp => {
          Swal.fire('Actualizado', `Paciente actualizado correctamente`, 'success');
          this.router.navigateByUrl(`/dashboard/examenes-tecnologos`);
        }, (err) => {
          Swal.fire('Error', err.error.errors.nombre, 'error');
          console.log(err);
        }
        )
    }
  }

  public cargarUsuarios() {
    this.usuarioService.cargarUsuarios()
      .subscribe(usuarios => {
        this.usuarios = usuarios;
        this.usuariosTemp = usuarios;
      });
  }

  public cargarMedicos() {
    this.medicoService.cargarMedicos()
      .subscribe( medicos => {
        this.medicos = medicos;
      });
  }

}
